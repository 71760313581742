import { useAuth } from '@/context/auth';
import RecentPropertyService from '@/services/recent-property.service';
import { AppObject } from '@/types';
import {
  IPropertyResponseItem,
  transformObjectToQuery,
  transformProperty,
} from '@/utility';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { PropertyCard } from '../property';
import Slider from '../ui/custom-slider/slider';
import useMediaBaseData from '@/hooks/use-media-base-data';
import { useTranslation } from '@/hooks/use-translation';

export const RecentProperties = () => {
  const [recentProperties, setRecentProperties] = useState([]);
  const { isAuthenticated } = useAuth();
  const { t, locale } = useTranslation();
  const count = useMediaBaseData<number>({
    xs: 1,
    sm: 1,
    md: 2,
    lg: 4,
    xl: 4,
    '2xl': 4,
    '3xl': 4,
    '4xl': 5,
    '5xl': 10,
  });

  const loadRecentProperties = useCallback(async () => {
    const recentPropertyService = new RecentPropertyService();
    const recentPropertyPropertyNumbers = recentPropertyService
      .getRecent()
      .map((property) => property.propertyNumber);

    if (recentPropertyPropertyNumbers.length === 0) return;

    const query: AppObject = {
      propertyNumber: recentPropertyPropertyNumbers,
    };

    const listings: IPropertyResponseItem[] = await axios
      .get(
        `/api/repliers/listings?${transformObjectToQuery({
          ...query,
          status: ['A'],
        })}`
      )
      .then((x) => x.data.listings);

    const properties = listings.map((item) => transformProperty(item, locale));

    setRecentProperties(properties);
  }, [locale]);

  useEffect(() => {
    loadRecentProperties();
  }, [loadRecentProperties]);

  return (
    !!recentProperties.length && (
      <div className="space-y-4 my-8" id="recommended">
        <p className="text-xl font-semibold">{t('Recently Viewed')}</p>
        <div className="flex">
          <Slider gap={32} count={count} padding={0} scrollSize={count}>
            {recentProperties.map((property) => (
              <PropertyCard
                key={property.id}
                property={property}
                fullWidth
                blurSoldProperty={!isAuthenticated}
                showFavIcon
              />
            ))}
          </Slider>
        </div>
      </div>
    )
  );
};
