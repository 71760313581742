import { Property } from '@/types';

const MAX_LIMIT = 25;

interface RecentItem {
  propertyNumber: string;
  addedOn: number;
}

export default class RecentPropertyService {
  public add(property: Property) {
    let recent = this.getRecent();

    // remove from existing recent list
    recent = recent.filter((x) => x.propertyNumber !== property.propertyNumber);

    // push new item at beginning
    recent.unshift({
      propertyNumber: property.propertyNumber,
      addedOn: Date.now(),
    });

    // set length
    recent.length = Math.min(recent.length, MAX_LIMIT);

    // sync sesssion
    localStorage.setItem('jdr_recent', JSON.stringify(recent));
  }

  public getRecent(): RecentItem[] {
    const recent = localStorage.getItem('jdr_recent');
    if (recent) {
      return JSON.parse(recent);
    }
    return [];
  }
}
