import { useProperty } from '@/context/property';
import { formatDate } from '@/utility/date';
import { NextSeo } from 'next-seo';
import { useEffect, useState } from 'react';

interface PropertySeoProps {
  city: string;
  neighborhood: string;
  type: string;
  count: number;
}

export const PropertySeo: React.FC<PropertySeoProps> = (props) => {
  const [city, setCity] = useState(props.city);
  const [neighborhood, setNeighborhood] = useState(props.neighborhood);
  const [type, setType] = useState(props.type);
  const [count, setCount] = useState(props.count);
  const { filter, pagination } = useProperty();

  useEffect(() => {
    setCity(filter.city);
    setNeighborhood(filter.neighborhood);
    setType(filter.neighborhood);
    setCount(pagination.count);
  }, [filter, pagination]);

  const month = formatDate(new Date(), 'MMM YYYY');
  const shortLocation = neighborhood || props.city || '';
  const longLocation = [city, neighborhood].filter(Boolean).join(', ');
  return (
    <NextSeo
      title={`${shortLocation ? `${shortLocation  } Real Estate - ` : ''}${
        props.count
      } Homes for ${type || 'sale'} | ${month}`}
      description={`The source for ${
        longLocation || ''
      } Real Estate - We currently have ${count} homes for sale${
        longLocation ? ` in ${  longLocation}` : ''
      }.`}
    />
  );
};
