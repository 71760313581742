import { View, useProperty } from '@/context/property';
import { useTranslatedArray } from '@/hooks/use-translated-array';
import { useTranslation } from '@/hooks/use-translation';
import clsx from 'clsx';
import React, { useMemo } from 'react';

interface SwtichOption {
  value: string;
  label: string;
}

interface SwitchProps {
  onChange: (value: string) => void;
  value: string;
  options: [SwtichOption, SwtichOption];
}

const Switch: React.FC<SwitchProps> = (props) => {
  const handleChange = () => {
    props.onChange(
      props.value === props.options[0].value
        ? props.options[1].value
        : props.options[0].value
    );
  };

  const selectedOption = useMemo(
    () =>
      props.options.find((x) => x.value === props.value) || props.options[0],
    [props.options, props.value]
  );
  const isFirst = useMemo(
    () => props.value === props.options[0].value,
    [props.value, props.options]
  );

  return (
    <div
      className="flex flex-row items-center justify-around w-20 h-8 bg-primary-50 rounded-2xl relative border-2 border-primary-200"
      onClick={handleChange}
      role="button"
    >
      <div className="flex items-center justify-center h-8 w-10 text-sm font-bold">
        {props.options[0].label}
      </div>
      <div className="flex items-center justify-center h-8 w-10 text-sm font-bold">
        {props.options[1].label}
      </div>
      <div
        style={{ marginTop: '0px', marginLeft: '0px' }}
        className={clsx(
          'flex items-center justify-center bg-red-400 rounded-full text-white text-sm font-bold h-7 w-10 inset-0 absolute z-20',
          {
            'translate-x-10 duration-1000': !isFirst,
            'translate-x-0 duration-1000': isFirst,
          }
        )}
      >
        {selectedOption.label}
      </div>
    </div>
  );
};

const SwitchOptions: [SwtichOption, SwtichOption] = [
  {
    label: 'Map',
    value: 'map',
  },
  {
    label: 'List',
    value: 'list',
  },
];

const PropertyViewSwitch: React.FC = () => {
  const { pagination, changeView, view } = useProperty();
  const isMapView = useMemo(() => view === 'map', [view]);
  const { t } = useTranslation();
  const tSwitchOptions = useTranslatedArray(SwitchOptions, 'label') as [
    SwtichOption,
    SwtichOption
  ];

  const handleChange = (value: string) => {
    changeView(value as View);
  };

  return (
    <div className="relative">
      <div className="flex justify-between items-center lg:space-x-4 flex-row mt-4 mb-4 absolute left-0 right-0">
        <h2
          className={clsx('text-lg md:text-lg font-bold mt-2', {
            visible: !isMapView,
            invisible: isMapView,
          })}
        >
          {t('Total {count} listings found.', { count: pagination.count })}
        </h2>
        <div
          className="rounded-2xl relative z-10"
          style={{
            transition: 'all 300ms',
            transform: isMapView
              ? 'translateX(-20px) translateY(-0px)'
              : 'translateX(0px) translateY(0px)',
            // boxShadow: isMapView ? '#00000044 0px 4px 8px 1px' : 'none',
            boxShadow: isMapView ? 'rgb(0 0 0 / 30%) 0px 1px 4px -1px' : 'none',
          }}
        >
          <Switch
            value={view}
            onChange={handleChange}
            options={tSwitchOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyViewSwitch;
