import { useWindowSize } from './use-window-size';

interface UseMediaBaseData<T> {
  xs: T;
  sm: T;
  md: T;
  lg: T;
  xl: T;
  '2xl': T;
  '3xl': T;
  '4xl': T;
  '5xl': T;
}

export default function useMediaBaseData<T>(props: UseMediaBaseData<T>) {
  const { width } = useWindowSize();

  if (width < 640) {
    return props.xs;
  } else if (width < 768) {
    return props.sm;
  } else if (width < 1024) {
    return props.md;
  } else if (width < 1280) {
    return props.lg;
  } else if (width < 1536) {
    return props.xl;
  } else if (width < 1920) {
    return props['2xl'];
  } else if (width < 2560) {
    return props['3xl'];
  } else if (width < 3840) {
    return props['4xl'];
  } else {
    return props['5xl'];
  }
}
