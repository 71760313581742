import { useMemo } from 'react';

export interface PropertyCardListLoader {
  count: number;
}
export const PropertyCardListLoader: React.FC<PropertyCardListLoader> = ({
  count,
}) => {
  const items = useMemo(
    () => new Array(count).fill(0).map((_, index) => ({ key: index })),
    [count]
  );
  return (
    <div className="grid grid-cols-1 gap-4">
      {items.map((_, index) => (
        <div
          key={index}
          className="h-24 bg-gray-200 animate-pulse rounded-md"
        ></div>
      ))}
    </div>
  );
};
