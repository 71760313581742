import { useCallback, useEffect, useState } from 'react';
import useScript from './useScript';
import AuthService from '@/services/auth.service';
import { useAuth } from '@/context/auth';
import Config from '@/config';
import { sentryCaptureException } from '@/utility';

let isReady = false;
const googleClientScriptURL = 'https://accounts.google.com/gsi/client';
// const oauthEndpointURL: string =
//   'https://oauth2.googleapis.com/tokeninfo?id_token=';

const clientId = Config.SIGNIN_CLIENT_ID;

export function useGoogleOneTapLogin() {
  const [ready, setReady] = useState(isReady);
  const script = useScript(googleClientScriptURL);
  const { loading, isAuthenticated } = useAuth();
  const [skipLogin, setSkipLogin] = useState(false);

  const callbackToUse = useCallback(async (data) => {
    if (!data?.credential) return;
    try {
      const authService = new AuthService();
      const res = await authService.oneTapSignin(data);
      await authService.saveAuth(res);
    } catch (e) {
      sentryCaptureException(e);
      alert(e.message);
    }
  }, []);

  useEffect(() => {
    if (!loading && isAuthenticated) {
      setSkipLogin(true);
    }
  }, [loading, isAuthenticated]);

  useEffect(() => {
    if (skipLogin || loading || isAuthenticated) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w = window as any;
    if (!ready && w.google && script === 'ready') {
      w.google.accounts.id.initialize({
        client_id: clientId,
        callback: callbackToUse,
      });
      isReady = true;
      setReady(true);
    }
    if (ready && script === 'ready') {
      w.google.accounts.id.prompt();

      return () => {
        // window.google.accounts.id.cancel();
      };
    }
  }, [script, ready, loading, isAuthenticated, callbackToUse, skipLogin]);

  return null;
}
