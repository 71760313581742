import React, { SVGProps } from 'react';

export default function BathIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M480,234.679H53.333V54.839c0-12.16,11.307-22.827,24.213-22.827h44.587c12.587,0,22.72,10.24,22.827,22.72
                c-20.8,5.227-38.293,24.427-38.293,44.693v28.587c0,5.867,4.8,10.667,10.667,10.667H192c5.867,0,10.667-4.8,10.667-10.667V99.426
                c0-20.587-16.32-39.467-36.373-44.587c0-24.32-19.733-44.16-44.16-44.16H77.547C52.907,10.679,32,30.946,32,54.839v179.84
                c-17.6-0.107-31.893,14.187-32,31.787c0,9.173,3.84,17.813,10.667,23.893v55.893c0,43.413,19.307,80.64,50.56,104.853
                l-17.493,34.773c-2.667,5.227-0.533,11.627,4.8,14.293c5.227,2.667,11.627,0.533,14.293-4.8l16.427-32.747
                c21.547,11.52,45.653,17.493,70.08,17.28h213.333c24.427,0.213,48.533-5.76,70.08-17.28l16.427,32.747
                c2.667,5.227,9.067,7.36,14.293,4.8c5.227-2.56,7.36-9.067,4.8-14.293l-17.493-34.88c31.253-24.107,50.56-61.44,50.56-104.853
                v-55.893c6.72-5.867,10.667-14.507,10.667-23.573C512,248.973,497.707,234.679,480,234.679z M155.627,74.679
                c12.693,0,25.707,12.48,25.707,24.747v17.92H128v-17.92C128,87.586,142.4,74.679,155.627,74.679z M480,346.253
                c0,64.107-50.453,112.427-117.333,112.427H149.333C82.453,458.679,32,410.359,32,346.253v-47.573h448V346.253z M480,277.346H32.32
                c-5.333,0-10.133-3.84-10.88-9.067c-0.96-6.613,4.16-12.267,10.56-12.267h447.68c5.333,0,10.133,3.84,10.88,9.067
                C491.52,271.693,486.4,277.346,480,277.346z"
      />
    </svg>
  );
}
