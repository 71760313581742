import { useEffect, useRef, useState } from 'react';

export default function useBoundingClientRect(
  ref: React.RefObject<HTMLElement>,
  intervalValue = 100
) {
  const [rect, setRect] = useState<DOMRect>(null);
  const rectRef = useRef(null);

  useEffect(() => {
    rectRef.current = rect;
  }, [rectRef, rect]);

  useEffect(() => {
    const handleEvent = () => {
      if (!ref.current) return;
      let shouldUpdate = false;
      const bounds = ref.current.getBoundingClientRect();
      if (!rectRef.current) shouldUpdate = true;
      else if (bounds.x !== rectRef.current.x) shouldUpdate = true;
      else if (bounds.y !== rectRef.current.y) shouldUpdate = true;
      else if (bounds.width !== rectRef.current.width) shouldUpdate = true;
      else if (bounds.height !== rectRef.current.height) shouldUpdate = true;
      else if (bounds.top !== rectRef.current.top) shouldUpdate = true;
      else if (bounds.bottom !== rectRef.current.bottom) shouldUpdate = true;
      else if (bounds.left !== rectRef.current.left) shouldUpdate = true;
      else if (bounds.right !== rectRef.current.right) shouldUpdate = true;

      if (shouldUpdate) {
        setRect(bounds);
      }
    };

    handleEvent();
    const interval = setInterval(handleEvent, intervalValue || 100);
    return () => clearInterval(interval);
  }, [ref, setRect, rectRef, intervalValue]);

  return rect;
}
