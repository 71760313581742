import React, { useMemo, useState } from 'react';
import { Link } from '@/components/ui/link';
import FilterIcon from '@/icons/filter';
import clsx from 'clsx';

import { Baths, Beds, ListedInDaysOptions, Type } from './data';
import { IFilter, IFilterPropertyType, useProperty } from '@/context/property';
import { useAuth } from '@/context/auth';
import AuthService from '@/services/auth.service';
import { SearchDialog } from './search-dialog';
import { getSortBy } from './utils';
import { Chip } from './chip/chip';
import { ChipDropdown } from './chip/chip-dropdown';
import { ChipPrice } from './chip/chip-price';
import { ChipBedBath } from './chip/chip-bed-bath';
import { ChipDropdownMultiple } from './chip/chip-dropdown-multiple';
import {
  FavoriteUserListModal,
  SaveSearchListModal,
  SaveSearchModal,
} from '@/components/modals';
import BookmarkIcon from '@/icons/bookmark';
import CompareIcon from '@/icons/compare';
import Favorites from '@/icons/favorite';
import { useFavorite } from '@/context/favorite/hooks/use-favorite';
import { useCompare } from '@/context/compare/hooks/use-compare';
import { useTranslation } from '@/hooks/use-translation';
import { useTranslatedArray } from '@/hooks/use-translated-array';
import { useSearchLookup } from '@/module/home/SearchBox/useSearchLookup';

const MAX_PRICE_SALE = 3000000;
const MAX_PRICE_RENT = 5000;
const PRICE_STEP_SALE = 10000;
const PRICE_STEP_RENT = 100;

export interface SearchBarProps {
  count: number;
}

export function SearchBar() {
  const [openListModal, setOpenListModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const { isAuthenticated } = useAuth();
  const { view, filter, filterUpdate } = useProperty();

  const { t } = useTranslation();
  const tType = useTranslatedArray(Type, 'label');
  const tBeds = useTranslatedArray(Beds, 'label');
  const tBaths = useTranslatedArray(Baths, 'label');

  const searchLookup = useSearchLookup();

  const filteredNeighborhoods = useMemo(() => {
    if (!filter.district) {
      return searchLookup.neighborhoods;
    }

    return searchLookup.neighborhoods.filter(
      (x) => x.district?.toLowerCase() === filter.district?.toLowerCase()
    );
  }, [searchLookup.neighborhoods, filter.district]);

  const handleSearch = (data: Partial<IFilter>) => {
    if (data.type === 'sold' || data.type === 'leased') {
      if (!isAuthenticated) {
        const authService = new AuthService();
        authService.openAuthModel();
        return;
      }
    }

    filterUpdate(data);
    setOpen(false);
  };

  const handleChangeType = (value: string) => {
    if (value === 'sold' || value === 'leased') {
      if (!isAuthenticated) {
        const authService = new AuthService();
        authService.openAuthModel();
        return;
      }
    }

    filterUpdate({
      type: value as IFilterPropertyType,
      minPrice: null,
      maxPrice: null,
    });
  };

  const handleRemoveChip = (
    key: keyof IFilter | 'price' | 'beds' | 'baths'
  ) => {
    switch (key) {
      case 'city':
      case 'neighborhood':
      case 'sortBy':
      case 'keywords':
      case 'listedInDays':
        filterUpdate({
          [key]: null,
        });
        break;
      case 'price':
        filterUpdate({
          minPrice: null,
          maxPrice: null,
        });
        break;
      case 'beds':
        filterUpdate({
          minBeds: null,
          maxBeds: null,
        });
        break;
      case 'baths':
        filterUpdate({
          minBaths: null,
          maxBaths: null,
        });
        break;
      case 'propertyType':
        filterUpdate({
          propertyType: [],
        });
        break;
    }
  };

  const handleOpenSavedSearchListModel = () => {
    if (!isAuthenticated) {
      const authService = new AuthService();
      authService.openAuthModel();
      return;
    }
    setOpenListModal(true);
  };

  const handleOpenSaveSearchModel = () => {
    if (!isAuthenticated) {
      const authService = new AuthService();
      authService.openAuthModel();
      return;
    }
    setOpenModal(true);
  };

  return (
    <div className={clsx('flex flex-col flex-1')}>
      <div className="flex items-start justify-between py-2">
        <div className="flex flex-row flex-wrap -ml-2 -mt-2">
          {/* <ChipDropdown
            value={filter.class}
            readonly
            data={PropertyTypes}
            onChange={(value) =>
              filterUpdate({
                class: value as IFilterPropertyClass,
              })
            }
          /> */}
          <ChipDropdown
            value={filter.type}
            readonly
            data={tType}
            onChange={(value) => {
              handleChangeType(value as IFilterPropertyType);
              filterUpdate({
                furnishing: null,
              });
            }}
          />
          <ChipDropdownMultiple
            value={filter.propertyType}
            data={searchLookup.tPropertyTypes}
            label={t('Property Type')}
            readonly
            onChangeValue={(values) => {
              filterUpdate({
                propertyType: values,
              });
            }}
            onRemove={() => handleRemoveChip('propertyType')}
          />
          {filter.city && (
            <Chip
              label={filter.city}
              onRemove={() => handleRemoveChip('city')}
            />
          )}
          <ChipDropdown
            value={filter.district}
            label={t('District')}
            readonly={!filter.district}
            data={searchLookup.districts}
            onChange={(value) =>
              filterUpdate({
                district: value,
              })
            }
            onRemove={() =>
              filterUpdate({
                district: null,
              })
            }
          />
          <ChipDropdown
            value={filter.neighborhood}
            label={t('Neighborhood')}
            data={filteredNeighborhoods}
            readonly={!filter.neighborhood}
            onChange={(value) =>
              filterUpdate({
                neighborhood: value,
              })
            }
            onRemove={() =>
              filterUpdate({
                neighborhood: null,
              })
            }
          />
          {/* {filter.neighborhood && (
            <Chip
              label={filter.neighborhood}
              onRemove={() => handleRemoveChip('neighborhood')}
            />
          )} */}
          <ChipPrice
            minPrice={filter.minPrice}
            maxPrice={filter.maxPrice}
            maxPriceLimit={
              filter.type === 'sale' || filter.type === 'sold'
                ? MAX_PRICE_SALE
                : MAX_PRICE_RENT
            }
            step={
              filter.type === 'sale' || filter.type === 'sold'
                ? PRICE_STEP_SALE
                : PRICE_STEP_RENT
            }
            readonly
            onChange={(values) => {
              filterUpdate({
                minPrice: values[0],
                maxPrice: values[1],
              });
            }}
          />

          <ChipBedBath
            label={t('Beds')}
            min={filter.minBeds}
            max={filter.maxBeds}
            data={tBeds}
            readonly
            onChange={(value) =>
              filterUpdate({
                minBeds: value.min,
                maxBeds: value.max,
              })
            }
          />
          <ChipBedBath
            label={t('Baths')}
            min={filter.minBaths}
            max={filter.maxBaths}
            data={tBaths}
            readonly
            onChange={(value) =>
              filterUpdate({
                minBaths: value.min,
                maxBaths: value.max,
              })
            }
          />
          {filter.type === 'lease' && (
            <ChipDropdown
              value={filter.furnishing}
              label={t('Furnishing')}
              data={searchLookup.tFurnishings}
              readonly={!filter.furnishing}
              onChange={(value) =>
                filterUpdate({
                  furnishing: value,
                })
              }
              onRemove={() =>
                filterUpdate({
                  furnishing: null,
                })
              }
            />
          )}
          {filter.keywords && (
            <Chip
              label={filter.keywords}
              highlight={Boolean(filter.keywords)}
              onRemove={() => handleRemoveChip('keywords')}
            />
          )}
          {filter.listedInDays && (
            <Chip
              label={
                filter.listedInDays &&
                ListedInDaysOptions.find(
                  (x) => x.value === String(filter.listedInDays)
                )?.label
              }
              highlight={Boolean(filter.listedInDays)}
              onRemove={() => handleRemoveChip('listedInDays')}
            />
          )}
          {filter.sortBy && (
            <ChipDropdown
              value={filter.sortBy}
              data={getSortBy(filter.type || 'sale')}
              onRemove={() => handleRemoveChip('sortBy')}
            />
          )}

          <button
            className="flex flex-row items-center bg-primary-50 border-2 border-primary-200 rounded-lg ml-2 mt-2 px-2 hover:bg-primary-200"
            onClick={handleOpenSaveSearchModel}
          >
            <BookmarkIcon className="h-6 w-6 mr-1 text-primary-500" />
            <span>{t('Save Search')}</span>
          </button>
        </div>
        <div className="flex gap-2 ml-2 flex-row">
          <div className="hidden md:flex gap-2 flex-row">
            <MoreActions onSavedSearchClick={handleOpenSavedSearchListModel} />
          </div>
          <button
            title={t('Advance filter')}
            className="py-1 px-2 bg-primary-400 text-white hover:bg-primary-500 transform duration-200 rounded"
            onClick={() => setOpen((prev) => !prev)}
          >
            {open ? (
              'Close'
            ) : (
              <FilterIcon className="h-6 w-6 fill-current text-white" />
            )}
          </button>
        </div>
      </div>
      <div className="flex flex-row gap-2 mb-2 md:hidden">
        <MoreActions onSavedSearchClick={handleOpenSavedSearchListModel} />
      </div>
      <hr className="-mx-2 md:-mx-8" />

      <div
        className={clsx(
          'bg-white w-full rounded-tr-md flex items-center space-y-4 md:space-y-0 flex-wrap'
        )}
      >
        <SearchDialog
          open={open}
          minPrice={filter.minPrice}
          maxPrice={filter.maxPrice}
          maxPriceLimit={
            filter.type === 'sale' || filter.type === 'sold'
              ? MAX_PRICE_SALE
              : MAX_PRICE_RENT
          }
          priceStep={
            filter.type === 'sale' || filter.type === 'sold'
              ? PRICE_STEP_SALE
              : PRICE_STEP_RENT
          }
          onChange={(values) => {
            filterUpdate({
              minPrice: values[0],
              maxPrice: values[1],
            });
          }}
          initialValue={filter}
          onClose={() => setOpen(false)}
          onSubmit={handleSearch}
        />
        <SaveSearchListModal
          open={openListModal}
          toggleModal={() => setOpenListModal((prev) => !prev)}
        />
        <FavoriteUserListModal />
        <SaveSearchModal
          open={openModal}
          toggleModal={() => setOpenModal((prev) => !prev)}
          filter={filter}
          view={view}
        />
      </div>

      <div
        onClick={() => setOpen(false)}
        className={clsx({
          'fixed bg-black bg-opacity-40 inset-0 z-40': open,
        })}
      ></div>
    </div>
  );
}

interface MoreActionsProps {
  onSavedSearchClick: () => void;
}

const MoreActions: React.FC<MoreActionsProps> = (props) => {
  const { favorites } = useFavorite();
  const { items } = useCompare();
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();

  const onClickValidateAuth = (e: React.MouseEvent) => {
    if (!isAuthenticated) {
      e.preventDefault();
      const authService = new AuthService();
      authService.openAuthModel();
    }
  };

  return (
    <>
      <Link
        href={`/properties/compare?propertyNumbers=${items
          .map((x) => x.propertyNumber)
          .join(',')}`}
        title={t('Compare')}
        className="py-1 px-2 bg-primary-400 text-white hover:bg-primary-500 transform duration-200 rounded relative"
        onClick={onClickValidateAuth}
      >
        <CompareIcon className="h-6 w-6 fill-current" />
        {Boolean(items.length) && (
          <span className="absolute inline-flex h-5 w-5 rounded-full bg-primary-600 text-white text-xs -top-1 -right-1  items-center justify-center">
            {items.length}
          </span>
        )}
      </Link>
      <Link
        href="/properties/favourite"
        title={t('My Favourites')}
        className="py-1 px-2 bg-primary-400 text-white hover:bg-primary-500 transform duration-200 rounded relative"
        onClick={onClickValidateAuth}
      >
        <Favorites className="h-6 w-6 fill-current" />
        {Boolean(favorites.length) && (
          <span className="absolute inline-flex h-5 w-5 rounded-full bg-primary-600 text-white text-xs -top-1 -right-1  items-center justify-center">
            {favorites.length}
          </span>
        )}
      </Link>

      {isAuthenticated && (
        <button
          title={t('Saved Searches')}
          onClick={props.onSavedSearchClick}
          className="py-1 px-2 bg-primary-400 text-white hover:bg-primary-500 transform duration-200 rounded"
        >
          <BookmarkIcon className="h-6 w-6 fill-current" />
        </button>
      )}
    </>
  );
};
