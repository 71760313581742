import { memo } from 'react';
import { useGoogleOneTapLogin } from './useGoogleOneTapLogin';

function GoogleOneTapLogin({ children = null }) {
  useGoogleOneTapLogin();
  return children;
}

export default memo(GoogleOneTapLogin);

export { useGoogleOneTapLogin };
