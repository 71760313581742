import { useEffect, useRef, useState } from 'react';

interface ScrollPosition {
  top: number;
  left: number;
}

export const useScrollPosition = (delay = 100) => {
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({
    top: 0,
    left: 0,
  });

  const scrollPositionRef = useRef(scrollPosition);

  useEffect(() => {
    scrollPositionRef.current = scrollPosition;
  }, [scrollPosition]);

  useEffect(() => {
    let position = {
      top: document.documentElement.scrollTop,
      left: document.documentElement.scrollLeft,
    };

    function intervalHandler() {
      if (
        scrollPositionRef.current.top !== position.top ||
        scrollPositionRef.current.left !== position.left
      ) {
        setScrollPosition(position);
      }
    }

    let intervalId: number;

    if (delay) {
      setInterval(intervalHandler, delay);
    }

    function handleScroll() {
      position = {
        top: document.documentElement.scrollTop,
        left: document.documentElement.scrollLeft,
      };

      if (!delay) {
        intervalHandler();
      }
    }
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [delay, scrollPositionRef]);

  return scrollPosition;
};
