import { ClickAway } from '@/components/ui/click-away';
import { DropdownData } from '@/components/ui/dropdown-container';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { Chip } from './chip';

interface ChipDropdownProps {
  data: DropdownData[];
  value: string;
  readonly?: boolean;
  label?: string;
  onRemove?: () => void;
  onChange?: (value: string) => void;
}

export function ChipDropdown(props: ChipDropdownProps) {
  const [show, setShow] = useState(false);

  const label = useMemo(() => {
    if (!props.value) {
      return props.label || 'NA';
    }

    const item = props.data.find((x) => x.value === props.value);

    if (!item) return props.label || 'NA';

    return item.label;
  }, [props.data, props.value, props.label]);

  const handleClick = () => {
    setShow(true);
  };

  const handleClickAway = () => {
    if (show) {
      setShow(false);
    }
  };

  const handleChange = (element: DropdownData) => {
    if (element?.disabled) return;
    props.onChange(element.value);
    setShow(false);
  };

  if (!props.onChange) {
    return (
      <Chip label={label} readonly={props.readonly} onRemove={props.onRemove} />
    );
  }

  return (
    <ClickAway onClickAway={handleClickAway} className="relative" value={show}>
      <Chip
        label={label}
        readonly={props.readonly}
        onRemove={props.onRemove}
        onClick={handleClick}
        highlight={Boolean(props.value)}
      />
      <ul
        className={clsx(
          'origin-top-right absolute left-0 mt-1 bg-white shadow-lg min-w-36 w-full border rounded z-50 max-h-80 overflow-auto',
          { hidden: !show },
          { block: show }
        )}
      >
        {props.data.map((element) => (
          <li
            key={element.value}
            onClick={() => handleChange(element)}
            className={clsx(
              'w-full  cursor-pointer transform duration-200',
              {
                ['text-gray-700 font-medium bg-gray-200']:
                  element.value && props.value == element.value,
                'px-4 text-sm font-semibold text-gray-400': element.disabled,
                'px-4 text-base  hover:bg-gray-100 ': !element.disabled,
              },
              'py-2'
            )}
          >
            {element.label}
          </li>
        ))}
      </ul>
    </ClickAway>
  );
}
