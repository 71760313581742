import { useTranslatedArray } from '@/hooks/use-translated-array';
import { Lookup } from '@/module/admin/create-update-property/types';
import NetworkService from '@/services/network.service';
import { useEffect, useState } from 'react';

interface LocationLookup extends Lookup {
  latitude: number;
  longitude: number;
}

interface NeighborhoodLookup extends LocationLookup {
  district: string;
}

interface DistrictLookup extends LocationLookup {
  city: string;
}

interface CityLookup extends LocationLookup {
  province: string;
}

let cache: Record<string, Lookup[]> | null = null;

export function useSearchLookup() {
  const [lookupNeighborhood, setLookupNeighborhood] = useState<
    NeighborhoodLookup[]
  >([]);
  const [lookupDistrict, setLookupDistrict] = useState<DistrictLookup[]>([]);
  const [lookupCity, setLookupCity] = useState<CityLookup[]>([]);
  const [lookupPropertyType, setLookupPropertyType] = useState<Lookup[]>([]);
  const [lookupFurnishing, setLookupFurnishing] = useState<Lookup[]>([]);

  const tLookupPropertyType = useTranslatedArray(lookupPropertyType, 'label');
  const tLookupFurnishing = useTranslatedArray(lookupFurnishing, 'label');

  useEffect(() => {
    if (cache) {
      setLookupNeighborhood(
        (cache.neighborhoods as NeighborhoodLookup[]) || []
      );
      setLookupDistrict((cache.districts as DistrictLookup[]) || []);
      setLookupCity((cache.cities as CityLookup[]) || []);
      setLookupPropertyType(cache.propertyTypes || []);
      setLookupFurnishing(cache.furnishings || []);
      return;
    }

    (async () => {
      const client = new NetworkService();
      const [locationLookups, generalLookups] = await Promise.all([
        client.get<Record<string, LocationLookup[]>>(
          '/api/listing/location/lookup'
        ),
        client.get<Record<string, LocationLookup[]>>('/api/listing/lookup'),
      ]);

      cache = {
        ...locationLookups,
        ...generalLookups,
      };

      setLookupNeighborhood(
        (cache.neighborhoods as NeighborhoodLookup[]) || []
      );
      setLookupDistrict((cache.districts as DistrictLookup[]) || []);
      setLookupCity((cache.cities as CityLookup[]) || []);
      setLookupPropertyType(cache.propertyTypes || []);
      setLookupFurnishing(cache.furnishings || []);
    })();
  }, []);

  return {
    cities: lookupCity,
    districts: lookupDistrict,
    neighborhoods: lookupNeighborhood,
    propertyTypes: lookupPropertyType,
    furnishings: lookupFurnishing,
    tPropertyTypes: tLookupPropertyType,
    tFurnishings: tLookupFurnishing,
  };
}
