import {
  LeaseMaxPrice,
  LeaseMinPrice,
  MaxPrice,
  MinPrice,
  SortBy,
  SortBySold,
} from './data';

export const getMaxPrice = (type: string) => {
  let maxPrice = [];
  if (type === 'sale' || type === 'sold') maxPrice = MaxPrice;
  if (type === 'lease' || type === 'leased') maxPrice = LeaseMaxPrice;
  return maxPrice;
};

export const getMinPrice = (type: string) => {
  let minPrice = [];
  if (type === 'sale' || type === 'sold') minPrice = MinPrice;
  if (type === 'lease' || type === 'leased') minPrice = LeaseMinPrice;
  return minPrice;
};

export const getSortBy = (type: string) => {
  if (type === 'sold' || type === 'leased') return SortBySold;
  return SortBy;
};
