import { Button } from '@/components/ui/button';
import { Image } from '@/components/ui/image';
import { List } from '@/components/ui/lists';
import { ListItem } from '@/components/ui/listitem';
import BathIcon from '@/icons/bath';
import BedIcon from '@/icons/bed';
import { Property } from '@/types';
import clsx from 'clsx';
import ArrowDown from '@/icons/arrow-down';
import ArrowUp from '@/icons/arrow-up';
import React, { useMemo } from 'react';
import NumberFormat from '@/components/ui/react-number-format';
import {
  FORMAT_DATE1,
  dateDiffInDays,
  formatDate,
  fromNowInDays,
} from '@/utility/date';
import AuthService from '@/services/auth.service';
import { useIsPropertyHovered } from '@/context/property/hooks/use-is-property-hovered';
import { useSinglePropertyFavorite } from '@/context/favorite/hooks/use-favorite';
import FavoiriteIcon from '@/icons/favorite';
import FavoriteBorderIcon from '@/icons/favorite-border';
import CheckboxIcon from '@/icons/checkbox';
import CheckboxBlankIcon from '@/icons/checkbox-blank';
import { useCompare } from '@/context/compare/hooks/use-compare';
import { getLastStatusText } from '@/utility';
import { useTranslation } from '@/hooks/use-translation';
import Link from 'next/link';

interface ISoldDescriptionProps {
  lastStatus: string;
  listPrice: number;
  soldPrice: number;
}

export function SoldDescription(props: ISoldDescriptionProps) {
  const { lastStatus, listPrice, soldPrice } = props;
  const priceDifference = useMemo(() => {
    return soldPrice - listPrice;
  }, [soldPrice, listPrice]);
  const priceDifferencePercentage = useMemo(() => {
    if (priceDifference === 0) return 0;
    return Number(((priceDifference / listPrice) * 100).toFixed(2));
  }, [priceDifference, listPrice]);

  const sellTypeLabel = useMemo(
    () => (lastStatus === 'Lsd' ? 'Leased' : 'Sold'),
    [lastStatus]
  );

  if (priceDifference === 0) {
    return (
      <div className="mt-2 text-sm text-gray-700 flex flex-row flex-wrap items-center">
        {sellTypeLabel} on listing price.
      </div>
    );
  }

  if (!soldPrice) {
    return (
      <div
        className="mt-2 text-sm text-gray-700 flex flex-row flex-wrap items-center"
        style={{ color: 'red' }}
      >
        Sold price/date not available.
      </div>
    );
  }

  return (
    <div
      className="mt-2 text-sm text-gray-700 flex flex-row flex-wrap items-center"
      style={{ color: priceDifference > 0 ? 'red' : 'green' }}
    >
      {sellTypeLabel} {priceDifference > 0 ? 'above' : 'below'} listing price
      by&nbsp;
      <NumberFormat
        value={Math.abs(priceDifference)}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
      />
      &nbsp;
      {priceDifference > 0 ? (
        <ArrowUp fill="red" className="h-6 w-6" />
      ) : (
        <ArrowDown fill="green" className="h-6 w-6" />
      )}
      {Math.abs(priceDifferencePercentage)}%
    </div>
  );
}

export interface PropertyCardProps {
  property: Property;
  onMouseHover?: () => void;
  onMouseLeave?: () => void;
  size?: 'sm' | 'md' | 'lg';
  view?: 'card' | 'list';
  blurSoldProperty?: boolean;
  fullWidth?: boolean;
  showFavIcon?: boolean;
  favInfo?: {
    isFav: boolean;
    toggleFav: () => void;
    loading: boolean;
    message: string;
    addedBy: string;
    addedOn: string;
  };
}

export function PropertyCard({
  property: {
    propertyNumber,
    bath,
    beds,
    squareMtr,
    name,
    listPrice,
    thumbnail,
    type,
    id,
    listDate,
    slug,
    lastStatus,
    soldPrice,
    soldDate,
    bedroomString,
    bathroomString,
  },
  size = 'md',
  onMouseHover,
  onMouseLeave,
  view = 'card',
  blurSoldProperty,
  fullWidth,
  showFavIcon,
  favInfo,
}: PropertyCardProps) {
  const isSold = useMemo(
    () => lastStatus === 'Sld' || lastStatus === 'Lsd',
    [lastStatus]
  );
  const highlight = useIsPropertyHovered([{ propertyNumber }], 'list');

  const { isFavorite, handleFavoriteAction, loading, isFavoriteUpdating } =
    useSinglePropertyFavorite(propertyNumber);
  const { isCompareFn, toggleCompareItem } = useCompare();
  const isItemInCompare = isCompareFn(propertyNumber);
  const { t } = useTranslation();

  const isPropertyFav = useMemo(
    () => (favInfo ? favInfo.isFav : isFavorite),
    [favInfo, isFavorite]
  );
  const isFavLoading = useMemo(
    () => (favInfo ? favInfo.loading : loading),
    [favInfo, loading]
  );

  const shouldShowFavIcon = useMemo(() => {
    if (!showFavIcon) return false;
    // if (isSold && !isPropertyFav) return false;
    return true;
  }, [showFavIcon]);

  function getListedLabel() {
    let label = 'Listed ';
    let date = listDate;
    if (lastStatus === 'Sld') {
      label = 'Sold ';
      date = soldDate;
    }
    if (lastStatus === 'Lsd') {
      label = 'Leased ';
      date = soldDate;
    }
    return label + fromNowInDays(date);
  }

  function getTypeLabel() {
    let label = type === 'Lease' ? 'For Rent' : 'For Sale';
    if (lastStatus === 'Sld') {
      label = 'Sold';
    }
    if (lastStatus === 'Lsd') {
      label = 'Leased';
    }
    return t(label);
  }

  const openAuthModal = () => {
    const authService = new AuthService();
    authService.openAuthModel();
  };

  const handleToggleFavorite = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (favInfo) {
      favInfo.toggleFav();
    } else {
      handleFavoriteAction();
    }
  };

  const handleToggleCompare = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    toggleCompareItem(propertyNumber);
  };

  if (view === 'list') {
    return (
      <div
        className={clsx(
          'border rounded-md hover:bg-gray-100',
          highlight && 'bg-gray-100'
        )}
        onMouseOver={onMouseHover}
        onMouseLeave={onMouseLeave}
      >
        <Link href={`/detail/${id}/${slug}`}>
          <a
            title={name}
            // target="_blank"
            // rel="noreferrer"
            className="relative flex items-start space-x-4 p-3"
          >
            <div className="flex flex-col">
              <div className="relative h-20 w-20 flex flex-col">
                <Image
                  src={`${thumbnail}?f=webp&w=200`}
                  layout="fill"
                  alt="Property Image"
                  className="group-hover:scale-110 transform duration-300 ease-in-out"
                  unoptimized
                  objectFit="cover"
                />
              </div>
              {isSold && (
                <div>
                  <span
                    className={clsx(
                      'rounded-br-md  px-4 block py-2 font-medium text-white text-sm',
                      {
                        ['bg-blue-600']: type === 'Lease',
                        'bg-red-400': type === 'Sale',
                      }
                    )}
                  >
                    {getTypeLabel()}
                  </span>
                </div>
              )}
            </div>
            <div className="flex-1 flex-col">
              <div className="flex flex-row items-start">
                <h2 className="text-base font-semibold group-hover:text-primary-500">
                  {name}
                </h2>
                {shouldShowFavIcon && (
                  <button
                    title={
                      isFavorite
                        ? 'Remove from favourites'
                        : 'Add to favourites'
                    }
                    onClick={handleToggleFavorite}
                    disabled={loading}
                    className={clsx('ml-1', {
                      'text-primary-500': !loading,
                      'text-gray-300': loading,
                    })}
                  >
                    {isFavorite ? (
                      <FavoiriteIcon width="25" height="25" />
                    ) : (
                      <FavoriteBorderIcon width="25" height="25" />
                    )}
                  </button>
                )}
              </div>

              <List className="flex items-center space-x-3 my-1">
                {beds > 0 && (
                  <ListItem className="flex items-center space-x-2">
                    <BedIcon className="h-4 text-primary-400 fill-current" />
                    <span className="block text-sm md:text-base">
                      {bedroomString}
                    </span>
                  </ListItem>
                )}
                {bath > 0 && (
                  <ListItem className="flex items-center space-x-2">
                    <BathIcon className="h-4 text-primary-400 fill-current" />
                    <span className="block text-sm md:text-base">
                      {bathroomString}
                    </span>
                  </ListItem>
                )}
              </List>
              <div className="flex justify-between flex-wrap">
                <label className="text-base font-semibold">
                  <NumberFormat
                    value={listPrice}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    className={clsx(isSold && 'line-through text-gray-500')}
                  />
                </label>
                {isSold && (
                  <label className="text-base font-semibold">
                    <NumberFormat
                      value={soldPrice}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </label>
                )}
              </div>
              {isSold && (
                <>
                  <SoldDescription
                    lastStatus={lastStatus}
                    soldPrice={Number(soldPrice)}
                    listPrice={listPrice}
                  />
                  <div
                    className="mt-2 text-sm text-gray-700 flex justify-between flex-wrap"
                    title={`${formatDate(
                      listDate,
                      FORMAT_DATE1
                    )} - ${formatDate(soldDate, FORMAT_DATE1)}`}
                  >
                    <div>
                      {formatDate(listDate, FORMAT_DATE1)} -{' '}
                      {soldDate && formatDate(soldDate, FORMAT_DATE1)}
                    </div>
                    <div>
                      {soldDate &&
                        `Sold in ${Math.max(
                          dateDiffInDays(soldDate, listDate),
                          0
                        )} days`}
                    </div>
                  </div>
                </>
              )}
            </div>
            {!isSold && (
              <div
                style={{ zIndex: 9 }}
                className={clsx('absolute bottom-0 m-0', {
                  ['left-0 m-0']: lastStatus === 'Lsd' || lastStatus === 'Sld',
                  ['right-0 m-0']: lastStatus !== 'Lsd' && lastStatus !== 'Sld',
                })}
              >
                <span
                  className={clsx(
                    'rounded-br-md  px-4 block py-2 font-medium text-white text-sm',
                    {
                      ['bg-blue-600']: type === 'Lease',
                      'bg-red-400': type === 'Sale',
                    }
                  )}
                >
                  {getTypeLabel()}
                </span>
              </div>
            )}
          </a>
        </Link>
      </div>
    );
  }

  return (
    <div className={clsx('border group relative', fullWidth && 'w-full')}>
      <div
        className="absolute top-0 right-0 left-0 h-16"
        style={{
          zIndex: 9,
          background: 'linear-gradient(#00000077, #00000000)',
        }}
      ></div>
      <div className="absolute top-0 right-0" style={{ zIndex: 9 }}>
        <span
          className={clsx(
            'rounded-bl-md  px-4 block py-2 font-medium text-white text-sm',
            { ['bg-blue-600']: type === 'Lease', 'bg-red-400': type === 'Sale' }
          )}
        >
          {getTypeLabel()}
        </span>
      </div>

      {shouldShowFavIcon && (
        <div className="absolute top-0 left-0" style={{ zIndex: 9 }}>
          <button
            title={
              isPropertyFav ? 'Remove from favourites' : 'Add to favourites'
            }
            onClick={handleToggleFavorite}
            disabled={isFavLoading || isFavoriteUpdating}
            className={clsx('ml-4 mt-3', {
              'text-white': !isFavLoading && !isFavoriteUpdating,
              'text-gray-300': isFavLoading || isFavoriteUpdating,
            })}
          >
            {isPropertyFav ? (
              <FavoiriteIcon width="25" height="25" />
            ) : (
              <FavoriteBorderIcon width="25" height="25" />
            )}
          </button>
        </div>
      )}
      {true && (
        <div className="absolute top-0 left-10" style={{ zIndex: 9 }}>
          <button
            title={!isItemInCompare ? 'Add to compare' : 'Remove from compare'}
            onClick={handleToggleCompare}
            className={clsx('ml-4 mt-3', 'text-white')}
          >
            {isItemInCompare ? (
              <CheckboxIcon width="25" height="25" />
            ) : (
              <CheckboxBlankIcon width="25" height="25" />
            )}
          </button>
        </div>
      )}
      <Link href={`/detail/${id}/${slug}`}>
        <a title={name} target="_blank" rel="noreferrer">
          <div
            className={clsx('relative  w-full', {
              'h-52 md:h-56': size == 'md',
              'h-36 md:h-40': size == 'sm',
            })}
          >
            <Image
              src={`${thumbnail}?f=webp&w=600`}
              layout="fill"
              alt="Property Image"
              className="group-hover:scale-110 transform duration-300 ease-in-out"
              unoptimized
              objectFit="cover"
              title={name}
            />
            <div className="absolute bottom-0 left-0" style={{ zIndex: 9 }}>
              <span
                className={clsx(
                  'rounded-tr-md px-4 block py-2 font-medium text-white bg-gray-500 text-sm bg-opacity-80'
                )}
              >
                {getListedLabel()}
              </span>
            </div>
          </div>
          <div className="p-4">
            <h2
              className="text-base font-semibold group-hover:text-primary-500"
              title={name}
            >
              {name}
            </h2>
            <div className="flex justify-between flex-wrap items-center">
              <List className="flex items-center space-x-3 my-3">
                {beds > 0 && (
                  <ListItem className="flex items-center space-x-2">
                    <BedIcon className="h-4 text-primary-400 fill-current" />
                    <span className="block text-sm md:text-base">
                      {bedroomString}
                    </span>
                  </ListItem>
                )}
                {bath > 0 && (
                  <ListItem className="flex items-center space-x-2">
                    <BathIcon className="h-4 text-primary-400 fill-current" />
                    <span className="block text-sm md:text-base">
                      {bathroomString}
                    </span>
                  </ListItem>
                )}
              </List>

              {!!squareMtr && squareMtr !== 'N/A' && (
                <span className="text-base font-semibold text-gray-600">
                  {squareMtr} Mt.
                </span>
              )}
            </div>

            <div className="flex justify-between flex-wrap">
              <label className="text-base font-semibold">
                <NumberFormat
                  value={listPrice}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  className={clsx(isSold && 'line-through text-gray-500')}
                />
              </label>
              {isSold && (
                <label className="text-base font-semibold">
                  <NumberFormat
                    value={soldPrice}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </label>
              )}
            </div>
            {isSold && (
              <>
                <SoldDescription
                  lastStatus={lastStatus}
                  soldPrice={Number(soldPrice)}
                  listPrice={listPrice}
                />
                <div
                  className="mt-2 text-sm text-gray-700 flex justify-between flex-wrap"
                  title={`${formatDate(listDate, FORMAT_DATE1)} - ${formatDate(
                    soldDate,
                    FORMAT_DATE1
                  )}`}
                >
                  <div>
                    {formatDate(listDate, FORMAT_DATE1)} -{' '}
                    {soldDate && formatDate(soldDate, FORMAT_DATE1)}
                  </div>
                  <div>
                    {soldDate &&
                      `Sold in ${Math.max(
                        dateDiffInDays(soldDate, listDate),
                        0
                      )} days`}
                  </div>
                </div>
              </>
            )}
          </div>
        </a>
      </Link>
      {blurSoldProperty && isSold && (
        <div className="absolute inset-0 z-10 backdrop-filter backdrop-blur-sm flex items-center justify-center">
          <Button className="rounded-md mt-4 mb-16" onClick={openAuthModal}>
            Login
          </Button>
        </div>
      )}
      {Boolean(favInfo) && (
        <div className="px-4 pb-2 -mt-2 text-xs text-gray-600 flex-1">
          <div>
            <span>
              Added on <b>{formatDate(favInfo.addedOn, FORMAT_DATE1)}</b>
            </span>
            &nbsp;
            {Boolean(favInfo.addedBy) && (
              <span>
                by <b>{favInfo.addedBy}</b>
              </span>
            )}
          </div>
          {Boolean(favInfo.message) && (
            <div className="mt-1">{favInfo.message}</div>
          )}
        </div>
      )}
      {lastStatus === 'Exp' ||
        (lastStatus === 'Ter' && (
          <div className="bg-red-500 text-white px-2 py-1 text-xs">
            {getLastStatusText(lastStatus)}
          </div>
        ))}
    </div>
  );
}
