import React, { SVGProps } from 'react';

export default function ArrowUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 14L12 9L17 14H7Z" />
    </svg>
  );
}
