import { ClickAway } from '@/components/ui/click-away';
import { DropdownData } from '@/components/ui/dropdown-container';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { Chip } from './chip';

interface ChipPriceProps {
  label: string;
  min: number;
  max: number;
  open?: string;
  readonly?: boolean;
  onRemove?: () => void;
  data: DropdownData[];
  onChange: ({ min, max }: { min: number; max: number }) => void;
}

function getCheckedValue(data: Pick<ChipPriceProps, 'min' | 'max'>) {
  const { min, max } = data;
  if (min && max) {
    return `${min}`;
  }
  if (min) {
    return `${min}+`;
  }
  return '';
}

export function ChipBedBath(props: ChipPriceProps) {
  const [show, setShow] = useState(false);

  const values = useMemo(() => getCheckedValue(props), [props]);

  const { data, onChange } = props;
  const label: string | React.ReactElement = useMemo(() => {
    if (props.min && !props.max) {
      return `${props.label}: ${props.min}+`;
    } else if (props.min && props.max) {
      return `${props.label}: ${String(props.min)}`;
    }

    return props.label;
  }, [props.label, props.min, props.max]);

  const handleClick = () => {
    setShow(true);
  };

  const handleClickAway = () => {
    if (show) {
      setShow(false);
    }
  };

  const handleChange = (element: DropdownData) => {
    const value = element.value;
    let min: number = null;
    let max: number = null;
    if (value) {
      const parsedValue = Number(value.replace(/\+/g, ''));
      min = parsedValue;
      if (!value.endsWith('+')) {
        max = parsedValue;
      }
    }
    onChange({ min: min, max: max });
    setShow(false);
  };

  if (!props.onChange) {
    return (
      <Chip label={label} readonly={props.readonly} onRemove={props.onRemove} />
    );
  }

  return (
    <ClickAway onClickAway={handleClickAway} className="relative" value={show}>
      <Chip
        label={label}
        readonly={props.readonly}
        onClick={handleClick}
        highlight={Boolean(props.min)}
      />
      <ul
        className={clsx(
          'origin-top-right absolute left-0 mt-1 bg-white shadow-lg min-w-36 w-full border rounded z-50 max-h-80 overflow-auto',
          { hidden: !show },
          { block: show }
        )}
      >
        {data.map((element) => (
          <li
            key={element.value}
            onClick={() => handleChange(element)}
            className={clsx(
              'w-full  cursor-pointer transform duration-200',
              {
                ['text-gray-700 font-medium bg-gray-200']:
                  element.value && values == element.value,
                'px-4 text-sm font-semibold text-gray-400': element.disabled,
                'px-4 text-base  hover:bg-gray-100 ': !element.disabled,
              },
              'py-2'
            )}
          >
            {element.label}
          </li>
        ))}
      </ul>
    </ClickAway>
  );
}
