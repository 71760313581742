import useBoundingClientRect from '@/hooks/use-bounding-client-rect';
import ArrowRight from '@/icons/arrow-right';
import clsx from 'clsx';
import { useMemo, useRef, useState } from 'react';
import styles from './slider.module.css';

interface SliderProps {
  gap: number;
  count: number;
  padding: number;
  scrollSize: number;
  children: React.ReactNode[];
}

export default function Slider({
  gap,
  count,
  padding,
  scrollSize,
  children,
}: SliderProps) {
  const ref = useRef();
  const bounds = useBoundingClientRect(ref);
  const [pageIndex, setPageIndex] = useState(0);

  const cardWidth = useMemo(
    () =>
      bounds?.width
        ? (bounds.width - gap * (count - 1) - padding * 2) / count
        : 0,
    [bounds?.width, gap, padding, count]
  );

  const marginLeft = useMemo(
    () => -(cardWidth + gap) * scrollSize * pageIndex + padding,
    [cardWidth, pageIndex, scrollSize, gap, padding]
  );

  const shouldShowPrevious = useMemo(() => {
    return pageIndex > 0;
  }, [pageIndex]);

  const shouldShowNext = useMemo(() => {
    return pageIndex * scrollSize + count < children.length;
  }, [pageIndex, scrollSize, count, children.length]);

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: marginLeft,
          transition: 'margin-left 1s',
        }}
      >
        {children.map((slide, index) => (
          <div
            key={index}
            style={{
              width: cardWidth,
              minWidth: cardWidth,
              height: '100%',
              marginLeft: index > 0 ? gap / 2 : 0,
              marginRight: index < children.length - 1 ? gap / 2 : 0,
              display: 'flex',
            }}
          >
            {slide}
          </div>
        ))}
      </div>
      {shouldShowPrevious && (
        <button
          aria-label="Previous"
          className={clsx(styles.sliderNavButton, styles.sliderNavPrevious)}
          onClick={() => setPageIndex((index) => index - 1)}
        >
          <div style={{ marginRight: -30 }}>
            <ArrowRight
              width={24}
              height={24}
              fill="currentColor"
              style={{ transform: 'rotate(180deg)' }}
            />
          </div>
        </button>
      )}
      {shouldShowNext && (
        <button
          aria-label="Next"
          className={clsx(styles.sliderNavButton, styles.sliderNavNext)}
          onClick={() => setPageIndex((index) => index + 1)}
        >
          <div style={{ marginLeft: -30 }}>
            <ArrowRight width={24} height={24} fill="currentColor" />
          </div>
        </button>
      )}
    </div>
  );
}
