import React from 'react';

export interface PropertyFeatureCardProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  label: string | React.ReactNode;
  title: string;
  icon?: React.ReactNode;
  bgcolor?: string;
  color?: string;
}

export function PropertyFeatureCard({
  icon,
  label,
  title,
  bgcolor,
  color,
  ...rest
}: PropertyFeatureCardProps) {
  if (!label) return null;

  return (
    <div className="space-y-2 flex-1" {...rest}>
      <p className="text-lg font-normal text-gray-600">{title}</p>
      <div className="flex space-x-4 align-top font-bold">
        {icon}
        <span style={{ backgroundColor: bgcolor, color }}>{label}</span>
      </div>
    </div>
  );
}
