import { useMemo } from 'react';
import { useTranslation } from './use-translation';

export function useTranslatedArray<T>(array = [] as T[], key: keyof T): T[] {
  const { t } = useTranslation();

  return useMemo(
    () =>
      array.map((item) => ({
        ...item,
        [key]: t(item[key] as unknown as string),
      })),
    [array, key, t]
  );
}
