import NumberFormat from '@/components/ui/react-number-format';
import { useProperty } from '@/context/property';
import React, { useEffect, useMemo, useState } from 'react';
import ArrowUp from '@/icons/arrow-up';
import clsx from 'clsx';
import { Property } from '@/types';
import ArrowDown from '@/icons/arrow-down';
import { useIsPropertyHovered } from '@/context/property/hooks/use-is-property-hovered';
import axios from 'axios';
import {
  getApiLinkQuery,
  transformObjectToQuery,
  transformProperty,
} from '@/utility';
import { FORMAT_DATE1, dateDiffInDays, formatDate } from '@/utility/date';
import Spinner from '@/icons/spinner';
import CheckboxIcon from '@/icons/checkbox';
import CheckboxBlankIcon from '@/icons/checkbox-blank';
import { useCompare } from '@/context/compare/hooks/use-compare';

type PropertyCardProps = {
  sortKey: string;
  sortedKey: string;
  sortedOrder: string; // asc | desc
  onClick: (sortKey: string) => void;
  align?: 'left' | 'center' | 'right';
};

const SortableHeader: React.FC<PropertyCardProps> = ({
  children,
  sortKey,
  sortedKey,
  sortedOrder,
  onClick,
  align,
}) => {
  return (
    <th
      className={clsx(
        'px-6 py-2 text-xs text-gray-500',
        align === 'center'
          ? 'text-center'
          : align === 'right'
          ? 'text-right'
          : 'text-left'
      )}
    >
      <div
        className={clsx(
          'inline-flex flex-row items-center cursor-pointer relative',
          align === 'center'
            ? 'justify-center text-center'
            : align === 'right'
            ? 'justify-end text-right'
            : 'text-left'
        )}
        onClick={() => onClick(sortKey)}
      >
        {children}
        <ArrowUp
          fill="currentColor"
          className="absolute -right-6"
          width={24}
          height={24}
          style={{
            minWidth: 24,
            transition: 'all 300ms ease-in-out',
            transform:
              sortedOrder === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
            opacity: sortedKey === sortKey ? 1 : 0,
          }}
        />
      </div>
    </th>
  );
};

function compareFnString(a: string, b: string) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

function compareFnNumber(a: number, b: number) {
  return (a || 0) - (b || 0);
}

function isSold(property: Property) {
  return property.lastStatus === 'Sld' || property.lastStatus === 'Lsd';
}

function soldInDays(property: Property) {
  if (!isSold(property)) return null;
  return Math.max(dateDiffInDays(property.soldDate, property.listDate), 0);
}

function soldInDaysString(property: Property) {
  const days = soldInDays(property);
  if (days === null) return null;
  return `${days} days`;
}

function getPriceDifference(property: Property) {
  if (!isSold(property)) return null;
  return property.soldPrice - property.listPrice;
}

function getPriceDifferencePercentage(
  priceDifference: number,
  listPrice: number
) {
  if (!priceDifference) return 0;
  return Number(((priceDifference / listPrice) * 100).toFixed(2));
}

function Row({
  property,
  showSoldInfo,
}: {
  property: Property;
  showSoldInfo: boolean;
}) {
  const { changeHoveredInfo } = useProperty();
  const highlight = useIsPropertyHovered(
    [{ propertyNumber: property.propertyNumber }],
    'table'
  );
  const { isCompareFn, toggleCompareItem } = useCompare();
  const isInCompare = useMemo(
    () => isCompareFn(property.propertyNumber),
    [isCompareFn, property.propertyNumber]
  );

  const { listPrice } = property;
  const priceDifference = useMemo(() => {
    return getPriceDifference(property);
  }, [property]);
  const priceDifferencePercentage = useMemo(() => {
    return getPriceDifferencePercentage(priceDifference, listPrice);
  }, [priceDifference, listPrice]);

  return (
    <tr
      className={clsx(
        'whitespace-nowrap hover:bg-gray-100',
        highlight && 'bg-gray-100'
      )}
      onMouseOver={() => changeHoveredInfo([property.propertyNumber], 'table')}
      onMouseLeave={() => changeHoveredInfo([], null)}
    >
      <td className="px-2 text-sm text-gray-500">
        <button
          className="hover:bg-gray-200 p-2 rounded-full"
          onClick={() => toggleCompareItem(property.propertyNumber)}
        >
          {isInCompare && <CheckboxIcon width={20} height={20} />}
          {!isInCompare && <CheckboxBlankIcon width={20} height={20} />}
        </button>
      </td>
      <td className="px-6 py-2 text-sm text-gray-500">
        <a
          href={`/detail/${property.id}/${property.slug}`}
          target="_blank"
          rel="noreferrer"
          className="text-primary-500 hover:underline"
        >
          {property.propertyNumber}
        </a>
      </td>
      <td className="px-6 py-2 text-sm text-gray-500">{property.name}</td>
      <td className="px-6 py-2 text-sm text-gray-500">
        {property.address.city}
      </td>
      <td className="px-6 py-2 text-sm text-gray-500">
        {formatDate(property.listDate, FORMAT_DATE1)}
      </td>
      <td className="px-6 py-2 text-sm text-gray-500 text-right">
        <NumberFormat
          value={property.listPrice}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      </td>
      {showSoldInfo && (
        <React.Fragment>
          <td className="px-6 py-2 text-sm text-gray-500 text-right">
            {property.soldPrice ? (
              <NumberFormat
                value={property.soldPrice}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            ) : null}
          </td>
          <td className="px-6 py-2 text-sm text-gray-500">
            {property.soldDate
              ? formatDate(property.soldDate, FORMAT_DATE1)
              : null}
          </td>
          <td className="px-6 py-2 text-sm text-gray-500 text-right">
            {isSold(property) && priceDifference !== 0 && (
              <div
                className="text-sm text-gray-700 flex flex-row items-center justify-end"
                style={{ color: priceDifference > 0 ? 'red' : 'green' }}
              >
                <NumberFormat
                  value={Math.abs(priceDifference)}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
                {priceDifference > 0 ? (
                  <ArrowUp fill="red" className="h-6 w-6" />
                ) : (
                  <ArrowDown fill="green" className="h-6 w-6" />
                )}
              </div>
            )}
          </td>
          <td
            className="px-6 py-2 text-sm text-gray-500 text-right"
            style={{ color: priceDifference > 0 ? 'red' : 'green' }}
          >
            {isSold(property) &&
              priceDifference !== 0 &&
              `${Math.abs(priceDifferencePercentage)}%`}
          </td>
          <td className="px-6 py-2 text-sm text-gray-500">
            {soldInDaysString(property)}
          </td>
        </React.Fragment>
      )}
      <td className="px-6 py-2 text-sm text-gray-500">
        {property.bedroomString}
      </td>
      <td className="px-6 py-2 text-sm text-gray-500">
        {property.bathroomString}
      </td>
      <td className="px-6 py-2 text-sm text-gray-500">{property.areaString}</td>
      <td className="px-6 py-2 text-sm text-gray-500">
        {property.landSizeString}
      </td>
      <td className="px-6 py-2 text-sm text-gray-500">
        {property.parkingString}
      </td>
      <td className="px-6 py-2 text-sm text-gray-500 text-right">
        {property.annualTaxes ? (
          <NumberFormat
            value={property.annualTaxes}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        ) : null}
      </td>
    </tr>
  );
}

export default function PropertyTableView() {
  const { data, filter, view, map } = useProperty();
  const [properties, setProperties] = useState<Property[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadedMore, setLoadedMore] = useState(false);
  const [sortedKey, setSortedKey] = useState('listDate');
  const [sortedOrder, setSortedOrder] = useState('desc');
  const [search, setSearch] = useState('');

  const showLoadMoreButton = useMemo(
    () => !loadedMore && data.pagination.numPages > 1,
    [data.pagination.numPages, loadedMore]
  );

  useEffect(() => {
    setProperties(data.properties);
    setLoadedMore(false);
    setLoadingMore(false);
    setSearch('');
  }, [data.properties]);

  const sortedProperties = useMemo(() => {
    return properties
      .filter((x) => {
        if (!search) return true;
        return (
          x.name.toLowerCase().includes(search.toLowerCase()) ||
          x.propertyNumber.toLowerCase().includes(search.toLowerCase()) ||
          x.address.city?.toLowerCase().includes(search.toLowerCase())
        );
      })
      .sort((a, b) => {
        let sortValue = 0;
        if (sortedKey === 'propertyNumber') {
          sortValue = compareFnString(a.propertyNumber, b.propertyNumber);
        } else if (sortedKey === 'address') {
          sortValue = compareFnString(a.name, b.name);
        } else if (sortedKey === 'city') {
          sortValue = compareFnString(a.address.city, b.address.city);
        } else if (sortedKey === 'listDate') {
          sortValue = compareFnString(a.listDate, b.listDate);
        } else if (sortedKey === 'listPrice') {
          sortValue = compareFnNumber(a.listPrice, b.listPrice);
        } else if (sortedKey === 'soldDate') {
          sortValue = compareFnString(a.soldDate, b.soldDate);
        } else if (sortedKey === 'soldPrice') {
          sortValue = compareFnNumber(a.soldPrice, b.soldPrice);
        } else if (sortedKey === 'bedrooms') {
          sortValue = compareFnNumber(a.beds, b.beds);
        } else if (sortedKey === 'bathrooms') {
          sortValue = compareFnNumber(a.bath, b.bath);
        } else if (sortedKey === 'area') {
          sortValue = compareFnString(a.areaString, b.areaString);
        } else if (sortedKey === 'landsize') {
          sortValue = compareFnString(a.landSizeString, b.landSizeString);
        } else if (sortedKey === 'parking') {
          sortValue = compareFnNumber(a.parking, b.parking);
        } else if (sortedKey === 'annualpropertytax') {
          sortValue = compareFnNumber(a.annualTaxes, b.annualTaxes);
        } else if (sortedKey === 'soldin') {
          sortValue = compareFnNumber(soldInDays(a), soldInDays(b));
        } else if (sortedKey === 'soldInfoPrice') {
          sortValue = compareFnNumber(
            getPriceDifference(b),
            getPriceDifference(a)
          );
        } else if (sortedKey === 'soldInfoPrec') {
          sortValue = compareFnNumber(
            getPriceDifferencePercentage(getPriceDifference(b), b.listPrice),
            getPriceDifferencePercentage(getPriceDifference(a), a.listPrice)
          );
        }

        if (sortedOrder === 'desc') {
          sortValue = sortValue * -1;
        }

        return sortValue;
      });
  }, [properties, sortedKey, sortedOrder, search]);

  const handleChangeSort = (sortKey: string) => {
    if (sortedKey == sortKey) {
      setSortedOrder(sortedOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedKey(sortKey);
      setSortedOrder('asc');
    }
  };

  const handleLoadMore = async () => {
    const query = getApiLinkQuery(
      {
        type: filter.type,
        // class: filter.class,
        city: filter.city,
        district: filter.district,
        neighborhood: filter.neighborhood,
        keywords: filter.keywords,
        minPrice: filter.minPrice,
        maxPrice: filter.maxPrice,
        minBeds: filter.minBeds,
        maxBeds: filter.maxBeds,
        minBaths: filter.minBaths,
        maxBaths: filter.maxBaths,
        map: filter.map,
        page: filter.page,
        sortBy: filter.sortBy,
        listedInDays: filter.listedInDays,
        furnishing: filter.furnishing,
      },
      filter.page,
      view,
      map.drawing
    );

    query.resultsPerPage = 500;
    query.pageNum = 1;

    setLoadingMore(true);

    const response = await axios
      .get(`/api/repliers/listings?${transformObjectToQuery(query)}`)
      .then((x) => x.data);

    setLoadingMore(false);
    setLoadedMore(true);
    setProperties((response.listings || []).map(transformProperty));
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);

    if (!loadedMore && event.target.value) {
      handleLoadMore();
    }
  };

  return (
    <div className="flex flex-wrap flex-col flex-1 mt-6 mb-2 md:my-6">
      <div className="mb-2 mt-0 lg:-mt-12 mr-2 flex flex-row justify-end">
        <input
          className="border px-2 py-1 hover:outline outline-primary-500 outline-2 rounded w-72"
          placeholder="Search"
          value={search}
          onChange={handleChangeSearch}
        />
      </div>
      <div className="flex overflow-x-auto w-full">
        <div className="flex flex-col flex-1">
          <div className="w-full">
            <div className="border-b border-gray-200 shadow">
              <table className="w-full table-auto divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <td>&nbsp;</td>
                    <SortableHeader
                      sortKey="propertyNumber"
                      sortedKey={sortedKey}
                      sortedOrder={sortedOrder}
                      onClick={handleChangeSort}
                    >
                      Property Number
                    </SortableHeader>
                    <SortableHeader
                      sortKey="address"
                      sortedKey={sortedKey}
                      sortedOrder={sortedOrder}
                      onClick={handleChangeSort}
                    >
                      Address
                    </SortableHeader>
                    <SortableHeader
                      sortKey="city"
                      sortedKey={sortedKey}
                      sortedOrder={sortedOrder}
                      onClick={handleChangeSort}
                    >
                      City
                    </SortableHeader>
                    <SortableHeader
                      sortKey="listDate"
                      sortedKey={sortedKey}
                      sortedOrder={sortedOrder}
                      onClick={handleChangeSort}
                    >
                      Listed On
                    </SortableHeader>
                    <SortableHeader
                      sortKey="listPrice"
                      sortedKey={sortedKey}
                      sortedOrder={sortedOrder}
                      onClick={handleChangeSort}
                      align="right"
                    >
                      Price
                    </SortableHeader>
                    {(filter.type === 'sold' || filter.type === 'leased') && (
                      <React.Fragment>
                        <SortableHeader
                          sortKey="soldPrice"
                          sortedKey={sortedKey}
                          sortedOrder={sortedOrder}
                          onClick={handleChangeSort}
                          align="right"
                        >
                          Sold Price
                        </SortableHeader>
                        <SortableHeader
                          sortKey="soldDate"
                          sortedKey={sortedKey}
                          sortedOrder={sortedOrder}
                          onClick={handleChangeSort}
                        >
                          Sold On
                        </SortableHeader>
                        <SortableHeader
                          sortKey="soldInfoPrice"
                          sortedKey={sortedKey}
                          sortedOrder={sortedOrder}
                          onClick={handleChangeSort}
                        >
                          Diff Price
                        </SortableHeader>
                        <SortableHeader
                          sortKey="soldInfoPrec"
                          sortedKey={sortedKey}
                          sortedOrder={sortedOrder}
                          onClick={handleChangeSort}
                        >
                          Diff Prec
                        </SortableHeader>
                        <SortableHeader
                          sortKey="soldin"
                          sortedKey={sortedKey}
                          sortedOrder={sortedOrder}
                          onClick={handleChangeSort}
                        >
                          Sold In
                        </SortableHeader>
                      </React.Fragment>
                    )}
                    <SortableHeader
                      sortKey="bedrooms"
                      sortedKey={sortedKey}
                      sortedOrder={sortedOrder}
                      onClick={handleChangeSort}
                    >
                      Bedrooms
                    </SortableHeader>
                    <SortableHeader
                      sortKey="bathrooms"
                      sortedKey={sortedKey}
                      sortedOrder={sortedOrder}
                      onClick={handleChangeSort}
                    >
                      Bathrooms
                    </SortableHeader>
                    <SortableHeader
                      sortKey="area"
                      sortedKey={sortedKey}
                      sortedOrder={sortedOrder}
                      onClick={handleChangeSort}
                    >
                      Area
                    </SortableHeader>
                    <SortableHeader
                      sortKey="landsize"
                      sortedKey={sortedKey}
                      sortedOrder={sortedOrder}
                      onClick={handleChangeSort}
                    >
                      Land Size
                    </SortableHeader>
                    <SortableHeader
                      sortKey="parking"
                      sortedKey={sortedKey}
                      sortedOrder={sortedOrder}
                      onClick={handleChangeSort}
                    >
                      Parking
                    </SortableHeader>
                    <SortableHeader
                      sortKey="annualpropertytax"
                      sortedKey={sortedKey}
                      sortedOrder={sortedOrder}
                      onClick={handleChangeSort}
                      align="right"
                    >
                      Annual Property Tax
                    </SortableHeader>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-100">
                  {sortedProperties.map((property) => (
                    <Row
                      key={property.propertyNumber}
                      property={property}
                      showSoldInfo={
                        filter.type === 'sold' || filter.type === 'leased'
                      }
                    />
                  ))}
                </tbody>
                {showLoadMoreButton && (
                  <tfoot>
                    <tr>
                      <td colSpan={2} className="text-left px-4 py-2">
                        <button
                          className="border-2 border-gray-200 bg-gray-200 rounded px-2 py-1 text-gray-600 hover:bg-gray-300 hover:border-gray-300 flex items-center justify-center"
                          onClick={handleLoadMore}
                          disabled={loadingMore}
                        >
                          {loadingMore ? (
                            <React.Fragment>
                              <Spinner className="h-4 w-4 text-gray-400 spin" />
                              &nbsp; Loading...
                            </React.Fragment>
                          ) : (
                            'Load more'
                          )}
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
