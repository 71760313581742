import React, { useState } from 'react';

import CallIcon from '@/icons/call';
import EmailIcon from '@/icons/email';
import LocationIcon from '@/icons/location';
import { Button } from '@/components/ui/button';
import { ContactModal } from '@/components/modals';
import { AgentInfo } from '@/hooks/use-agent-info';
import { useTranslation } from '@/hooks/use-translation';

export interface IPropertyContactInfoProps {
  message?: string;
  isSold?: boolean;
  agentInfo?: AgentInfo;
}

export function PropertyContactInfo({
  message,
  isSold,
  agentInfo,
}: IPropertyContactInfoProps) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <p className="text-lg font-bold ">{t('Contact Info')}</p>

      <div className="flex items-center space-x-3" id="address">
        <LocationIcon className="h-5 fill-current text-primary-400" />
        <div>
          <p className="font-bold">{t('Address')}</p>
          {agentInfo?.addressParts?.map((part, index) => (
            <React.Fragment key={index}>
              {index > 0 && <br />}
              <span className="">{part}</span>
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="flex items-center space-x-3" id="phone">
        <CallIcon className="h-5 fill-current text-primary-400" />
        <div>
          <p className="font-bold">{t('Phone')}</p>
          <a href={`tel:${agentInfo?.phone}`}>
            <span className="">{agentInfo?.phone}</span>
          </a>
        </div>
      </div>

      <div className="flex items-center space-x-3" id="email">
        <EmailIcon className="h-5 fill-current text-primary-400" />
        <div>
          <p className="font-bold">{t('Email')}</p>
          <a href={`mailto:${agentInfo?.email}`}>
            <span className="">{agentInfo?.email}</span>
          </a>
        </div>
      </div>

      <div className="flex justify-center">
        <Button onClick={() => setOpen(true)}>
          {!isSold ? t('Contact Agent') : t('Contact for more information')}
        </Button>
      </div>

      <ContactModal
        open={open}
        toggleModal={() => setOpen((prev) => !prev)}
        message={message}
        booking
        agentInfo={agentInfo}
      />
    </div>
  );
}
