import { ClickAway } from '@/components/ui/click-away';
import DropdownContainer, {
  DropdownData,
} from '@/components/ui/dropdown-container';
import { useMemo, useState } from 'react';
import { Chip } from './chip';

interface ChipDropdownMultipleProps {
  data: DropdownData[];
  label: string;
  value: string[];
  readonly?: boolean;
  onRemove?: () => void;
  onChangeValue?: (value: string[]) => void;
}

export function ChipDropdownMultiple(props: ChipDropdownMultipleProps) {
  const { data, value, onChangeValue } = props;
  const [show, setShow] = useState(false);
  const label = useMemo(() => {
    if (!props.value?.length) {
      return props.label;
    }

    const items = props.data.filter((x) => props.value.includes(x.value));

    if (!items.length) return props.label;

    return items.map((x) => x.label).join(', ');
  }, [props.data, props.value, props.label]);

  const selectedItems = useMemo(() => {
    let result = [];
    if (!value || (Array.isArray(value) && value.length === 0)) {
      result = [data.find((x) => !x.value)];
    } else if (typeof value === 'string') {
      result = [data.find((item) => item.value === value)];
    } else if (Array.isArray(value)) {
      result = data.filter((item) => value.includes(item.value));
    }

    result = result.filter((x) => !!x);

    if (!result[0]) {
      result = [
        {
          label: '',
          value: '',
        },
      ];
    }

    return result;
  }, [data, value]);

  const handleClick = () => {
    setShow(true);
  };

  const handleClickAway = () => {
    if (show) {
      setShow(false);
    }
  };

  const toggleDropdown = () => {
    setShow((prev) => !prev);
  };

  if (!props.onChangeValue) {
    return (
      <Chip label={label} readonly={props.readonly} onRemove={props.onRemove} />
    );
  }

  return (
    <ClickAway onClickAway={handleClickAway} className="relative" value={show}>
      <Chip
        label={label}
        readonly={props.readonly}
        onClick={handleClick}
        onRemove={props.onRemove}
        highlight={Boolean(props.value?.length)}
      />
      <div className="max-w-lg">
        <DropdownContainer
          label={label}
          isShow={show}
          data={data}
          selectedItems={selectedItems}
          selectedValue={value}
          isMultiselect
          onChangeValue={onChangeValue}
          toggleDropdown={toggleDropdown}
          position="bottom-left"
        />
      </div>
    </ClickAway>
  );
}
