import CheckCircleIcon from '@/icons/check-circle';
import clsx from 'clsx';
import React from 'react';

export interface DropdownData {
  label: string;
  value: string;
  disabled?: boolean;
}

interface DropdownContainerProps {
  data: DropdownData[];
  selectedValue: string | string[];
  onChangeValue?: (value: string | string[]) => void;
  label?: string;
  error?: string;
  dense?: boolean;
  multiselect?: boolean;
  isShow?: boolean;
  isDance?: boolean;
  isMultiselect?: boolean;
  toggleDropdown?: () => void;
  selectedItems: DropdownData[];
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

const DropdownContainer: React.FC<DropdownContainerProps> = (props) => {
  const {
    isShow,
    isDance,
    isMultiselect,
    onChangeValue,
    toggleDropdown,
    data,
    selectedItems,
    selectedValue,
    position,
  } = props;

  const handleChange = (value: DropdownData) => {
    if (!isMultiselect) toggleDropdown();

    if (onChangeValue) {
      if (!isMultiselect) {
        onChangeValue(value.value as string);
      } else {
        if (value.disabled) return;
        if (selectedValue.includes(value.value as string)) {
          onChangeValue(
            (selectedValue as string[]).filter((x) => x !== value.value && !!x)
          );
        } else {
          onChangeValue([
            ...(selectedValue as string[]),
            value.value as string,
          ]);
        }
      }
    }
  };

  return (
    <ul
      className={clsx(
        'absolute mt-1 bg-white shadow-lg min-w-full max-w-7xl border rounded z-50 max-h-80 overflow-auto',
        { hidden: !isShow },
        { block: isShow }
      )}
      style={{
        minWidth: 200,
        ...(position === 'top-left' || position === 'top-right'
          ? { bottom: '100%' }
          : { top: '100%' }),
        ...(position === 'top-left' || position === 'bottom-left'
          ? { left: '0px' }
          : { right: '0px' }),
      }}
    >
      {data.map((element) => (
        <li
          key={element.value as string}
          onClick={
            () => (element?.disabled ? () => null : handleChange(element))
            // element?.disabled ? () => {} : handleChange(element)
          }
          className={clsx(
            ' flex gap-1 items-center  cursor-pointer transform duration-200 ',
            {
              [' text-gray-700 font-medium bg-primary-50']:
                element.value &&
                selectedItems.findIndex((x) => x.value === element.value) !==
                  -1,
              'px-4 text-sm font-semibold text-gray-400': element.disabled,
              'px-4 text-base  hover:bg-gray-100 ': !element.disabled,
            },
            isDance ? 'py-2' : 'py-3'
          )}
        >
          {isMultiselect && (
            <div style={{ height: 20, width: 20 }} className="text-primary-500">
              {selectedItems.findIndex((x) => x.value === element.value) !==
                -1 &&
                selectedItems.length > 0 &&
                Boolean(element.value) && (
                  <CheckCircleIcon height={20} width={20} />
                )}
            </div>
          )}
          {element.label}
        </li>
      ))}
    </ul>
  );
};
export default DropdownContainer;
