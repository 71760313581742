import React from 'react';
import { Image } from '@/components/ui/image';

export interface PropertyVirtualTourProps {
  thumbnail: string;
  link: string;
}

export function PropertyVirtualTour({
  thumbnail,
  link,
}: PropertyVirtualTourProps) {
  return (
    <div className="space-y-4">
      <p className="text-xl font-semibold">Virtual Tour</p>

      <div
        id="virtualTour"
        className="h-96 w-full border rounded-sm flex justify-center items-center relative group"
      >
        <div
          className="absolute inset-0 z-30 flex justify-center items-center"
          style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}
        >
          <a href={link} target="_blank" rel="noopener noreferrer">
            <button className="border border-white text-white px-12 py-3 text-xl">
              View
            </button>
          </a>
        </div>
        <Image src={thumbnail} layout="fill" alt="Virtual Tour" />
      </div>
    </div>
  );
}

export default PropertyVirtualTour;
