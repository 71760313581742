import { Range as RangeComponent } from 'rc-slider';
import { useMemo } from 'react';

interface RangeProps {
  min: number;
  max: number;
  value: RangeValue;
  step?: number;
  onChange: (value: RangeValue) => void;
}

export type RangeValue = [number, number];

function isValueEqual(value: RangeValue, nextValue: RangeValue) {
  if (!value && !nextValue) return true;
  return value[0] === nextValue[0] && value[1] === nextValue[1];
}

export default function Range(props: RangeProps) {
  const safeValue: RangeValue = useMemo(() => {
    return [
      Math.max(props.min, props.value[0]),
      Math.min(props.max, props.value[1]),
    ];
  }, [props.value, props.min, props.max]);
  const handleChange = (value: RangeValue) => {
    if (isValueEqual(safeValue, value)) return;
    if (props.onChange) props.onChange(value);
  };

  return (
    <RangeComponent
      min={props.min}
      max={props.max}
      railStyle={{ backgroundColor: '#d1d5db' }}
      trackStyle={[{ backgroundColor: '#4dbdb9' }]}
      value={safeValue}
      step={props.step}
      onChange={handleChange}
    />
  );
}
