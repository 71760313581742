import React from 'react';
import { PropertyCard } from '@/components/property';
import { useProperty } from '@/context/property';
import { PropertyCardListLoader } from '../property-card-loader';
import { PropertyMapContainer } from '../property-map-container';
import { Pagination } from '@/components/ui/pagination';

import style from './style.module.css';
import { filterToQuery, transformObjectToQuery } from '@/utility';
import clsx from 'clsx';
import { useTranslation } from '@/hooks/use-translation';

export const PropertyMapView: React.FC = () => {
  const {
    properties,
    refreshing,
    pagination,
    filter,
    filterUpdate,
    changeHoveredInfo,
    view,
    map,
  } = useProperty();
  const { t } = useTranslation();

  return (
    <div className={clsx('mt-1', style['root'])}>
      <div className={style['property-list']}>
        <div className="h-180 overflow-auto">
          {refreshing ? (
            <PropertyCardListLoader count={4} />
          ) : properties?.length ? (
            <div className="grid grid-cols-1 gap-4">
              {properties.map((el) => (
                <PropertyCard
                  property={el}
                  key={el.id}
                  size="sm"
                  view="list"
                  showFavIcon
                  onMouseHover={() => {
                    changeHoveredInfo([el.propertyNumber], 'list');
                  }}
                  onMouseLeave={() => {
                    changeHoveredInfo([], null);
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="flex-1 items-center flex justify-center h-full w-full">
              <p className="text-2xl text-gray-400">No Properties Found</p>
            </div>
          )}
        </div>
        <div className="mt-4">
          <Pagination
            page={filter.page}
            count={pagination.count}
            numPages={pagination.numPages}
            loading={refreshing}
            getLink={(page: number) =>
              `/properties?${transformObjectToQuery(
                filterToQuery(
                  {
                    ...filter,
                    page,
                  },
                  view,
                  map.position
                )
              )}`
            }
            onClick={(page: number) => {
              filterUpdate({
                page,
              });
              document
                .querySelector('#property-main')
                ?.scrollIntoView({ behavior: 'smooth' });
            }}
          />
        </div>
      </div>
      <div className={clsx('relative', style['map-container'])}>
        <h2
          className="absolute top-14 font-bold drop-shadow-2xl bg-white bg-opacity-80 py-1 px-2 text-sm"
          style={{
            boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
            left: 10,
            zIndex: 1,
          }}
        >
          {t('Total {count} listings found.', { count: pagination.count })}
        </h2>
        <PropertyMapContainer />
      </div>
    </div>
  );
};
