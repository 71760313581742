import { Property } from '@/types';
import { HoveredArea } from '..';
import { usePropertyContext } from './use-property-context';

export function useIsPropertyHovered(
  properties: Pick<Property, 'propertyNumber'>[],
  area: HoveredArea
) {
  const context = usePropertyContext();

  if (!context) return false;

  const {
    state: { hoveredProperties, hoveredArea },
  } = context;

  if (hoveredArea === area) return false;
  if (!hoveredProperties.length) return false;
  if (properties.length === 0) return false;
  if (properties.length === 1 && hoveredProperties.length === 1) {
    return properties[0].propertyNumber === hoveredProperties[0];
  }
  if (properties.length === 1 && hoveredProperties.length > 1) {
    return hoveredProperties.some(
      (propertyNumber) => properties[0].propertyNumber === propertyNumber
    );
  }
  if (properties.length > 1 && hoveredProperties.length === 1) {
    return properties.some((x) => x.propertyNumber === hoveredProperties[0]);
  }

  return hoveredProperties.some((id) =>
    properties.some((x) => x.propertyNumber === id)
  );
}
