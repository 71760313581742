import React from 'react';
import { PropertyCard } from '@/components/property';
import { useProperty } from '@/context/property';
import ContentLoader from 'react-content-loader';
import { useTranslation } from '@/hooks/use-translation';

export const PropertyCardLoader: React.FC = () => {
  return (
    <ContentLoader viewBox="0 0 450 350" uniqueKey="PropertyCardLoader">
      <rect x="0" y="0" width="450" height="250" />
      <rect x="10" y="260" rx="10" ry="10" width="350" height="25" />
      <rect x="10" y="290" rx="10" ry="10" width="250" height="20" />
      <rect x="10" y="320" rx="10" ry="10" width="150" height="25" />
    </ContentLoader>
  );
};

export const PropertyListView: React.FC = () => {
  const { refreshing, properties } = useProperty();
  const { t } = useTranslation();

  if (refreshing) {
    return (
      <div className="grid grid-col-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {new Array(20).fill(0).map((_, index) => (
          <PropertyCardLoader key={String(index)} />
        ))}
      </div>
    );
  }

  if (!properties?.length) {
    return (
      <div className="flex items-center flex-1 flex-col">
        <p className="text-lg font-semibold text-gray-600">
          {t('No properties found.')}
        </p>
      </div>
    );
  }

  return (
    <div className="grid grid-col-1 md:grid-cols-2 lg:grid-cols-4 4xl:grid-cols-5 5xl:grid-cols-10 gap-8">
      {properties.map((el) => (
        <PropertyCard property={el} key={el.id} showFavIcon />
      ))}
    </div>
  );
};
