import React, { useEffect, useMemo, useState } from 'react';

import { Dropdown } from '@/components/ui/dropdown';
import { Input } from '@/components/ui/input';
import CloseIcon from '@/icons/close';
import clsx from 'clsx';

import { Baths, Beds, ListedInDaysOptions, Type } from './data';
import { IFilter, useProperty } from '@/context/property';
import { getSortBy } from './utils';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { formatCurrency } from '@/utility';
import { useTranslation } from '@/hooks/use-translation';
import { useTranslatedArray } from '@/hooks/use-translated-array';
import { useSearchLookup } from '@/module/home/SearchBox/useSearchLookup';

interface ISearchDialogProps {
  open: boolean;
  initialValue: Partial<IFilter>;
  minPrice: number;
  maxPrice: number;
  maxPriceLimit: number;
  priceStep: number;
  onClose: () => void;
  onSubmit: (data: Partial<IFilter>) => void;
  onChange?: (values: [number, number]) => void;
}

export function SearchDialog(props: ISearchDialogProps) {
  const { filter } = useProperty();
  const { open, onClose, onSubmit, initialValue } = props;
  const [form, setForm] = useState<Partial<IFilter>>({});
  const { t } = useTranslation();

  const propsValues: [number, number] = useMemo(() => {
    let minPrice = props.minPrice;
    let maxPrice = props.maxPrice;

    if (!minPrice) {
      minPrice = 0;
    }

    if (!maxPrice) {
      maxPrice = props.maxPriceLimit;
    }

    return [minPrice, maxPrice];
  }, [props.minPrice, props.maxPrice, props.maxPriceLimit]);

  const [values, setValues] = useState<[number, number]>(propsValues);

  useEffect(() => {
    setValues(propsValues);
  }, [propsValues]);

  const selectedBeds = useMemo(() => {
    if (form.minBeds && !form.maxBeds) {
      return `${form.minBeds}+`;
    } else if (form.minBeds && form.maxBeds) {
      return String(form.minBeds);
    }
    return null;
  }, [form.minBeds, form.maxBeds]);

  const selectedBaths = useMemo(() => {
    if (form.minBaths && !form.maxBaths) {
      return `${form.minBaths}+`;
    } else if (form.minBaths && form.maxBaths) {
      return String(form.minBaths);
    }
    return null;
  }, [form.minBaths, form.maxBaths]);

  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [open]);

  useEffect(() => {
    if (!open) return;

    setForm({
      type: initialValue.type,
      // class: initialValue.class,
      keywords: initialValue.keywords,
      maxBaths: initialValue.maxBaths,
      minBaths: initialValue.minBaths,
      maxBeds: initialValue.maxBeds,
      minPrice: initialValue.minPrice,
      maxPrice: initialValue.maxPrice,
      minBeds: initialValue.minBeds,
      propertyType: initialValue.propertyType || [],
      sortBy: initialValue.sortBy,
      listedInDays: initialValue.listedInDays,
      furnishing: initialValue.furnishing,
    });
  }, [open, initialValue]);

  const tType = useTranslatedArray(Type, 'label');
  const searchLookup = useSearchLookup();
  const tBuildingTypes = useTranslatedArray(
    searchLookup.propertyTypes,
    'label'
  );
  const tBeds = useTranslatedArray(Beds, 'label');
  const tBaths = useTranslatedArray(Baths, 'label');
  const tSortBy = useTranslatedArray(getSortBy(filter.type || 'sale'), 'label');
  const tListedInDaysOptions = useTranslatedArray(ListedInDaysOptions, 'label');

  const filteredNeighborhoods = useMemo(() => {
    if (!form.district) {
      return searchLookup.neighborhoods;
    }

    return searchLookup.neighborhoods.filter(
      (x) => x.district?.toLowerCase() === form.district?.toLowerCase()
    );
  }, [searchLookup.neighborhoods, form.district]);

  const handleSubmit = () => {
    onSubmit({
      ...form,
      minPrice: !values[0] ? null : values[0],
      maxPrice: values[1] === props.maxPriceLimit ? null : values[1],
    });
  };

  function handleFormValueChange<T>(value: T, key: keyof IFilter) {
    setForm((prev) => ({ ...prev, [key]: value }));
  }

  return (
    <div
      className={clsx(
        'p-2 md:py-4 fixed md:p-8 top-0 right-0 bottom-0 w-full sm:w-96',
        'bg-white z-50 shadow-md transform duration-200 overflow-y-auto',
        { 'right-0': open, '-right-full': !open }
      )}
      style={{ height: '100%' }}
    >
      <div className="flex items-center justify-between">
        <h2 className="text-base md:text-xl font-bold">
          {t('Search Filters')}
        </h2>
        <button onClick={onClose}>
          <CloseIcon className="h-6 w-6" />
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 my-6">
        {/* <div className={clsx('w-full')}>
          <Dropdown
            dense
            data={PropertyTypes}
            selectedValue={form.class}
            onChangeValue={(value) => handleFormValueChange(value, 'class')}
            label="Property Type"
          />
        </div> */}
        <div className={clsx('w-full')}>
          <Dropdown
            dense
            data={tType}
            selectedValue={form.type}
            onChangeValue={(v) => {
              handleFormValueChange(v, 'type');
              handleFormValueChange('', 'furnishing');
            }}
            label={t('Type')}
            position="bottom-left"
          />
        </div>

        <div className={clsx('w-full')}>
          <Dropdown
            dense
            multiselect
            data={tBuildingTypes}
            selectedValue={form.propertyType}
            onChangeValue={(v) => handleFormValueChange(v, 'propertyType')}
            label={t('Property Type')}
            position="bottom-left"
          />
        </div>
        <div className={clsx('w-full')}>
          <Dropdown
            dense
            data={searchLookup.districts}
            selectedValue={form.district}
            onChangeValue={(v) => handleFormValueChange(v, 'district')}
            label={t('District')}
            position="bottom-left"
          />
        </div>
        <div className={clsx('w-full')}>
          <Dropdown
            dense
            data={filteredNeighborhoods}
            selectedValue={form.neighborhood}
            onChangeValue={(v) => handleFormValueChange(v, 'neighborhood')}
            label={t('Neighborhood')}
            position="bottom-left"
          />
        </div>
        <div className={clsx('flex flex-col w-full')}>
          <div className="mb-1 text-xs text-gray-400">
            <span>{t('Price')}</span>
          </div>
          <div
            className={clsx(
              'flex flex-col border w-full text-left px-4 rounded gap-3 py-3'
            )}
          >
            <div className="flex text-sm text-gray-500">
              <span>{formatCurrency(values[0])}</span>&nbsp;&#8211;&nbsp;
              <span>{formatCurrency(values[1])}</span>
            </div>
            <div>
              <Range
                min={0}
                max={props.maxPriceLimit}
                step={props.priceStep}
                railStyle={{ backgroundColor: '#d1d5db' }}
                trackStyle={[{ backgroundColor: '#4dbdb9' }]}
                value={values}
                onChange={(values) => setValues(values as [number, number])}
              />
            </div>
          </div>
        </div>

        <div className={clsx('w-full')}>
          <Dropdown
            dense
            data={tBeds}
            selectedValue={selectedBeds}
            onChangeValue={(value: string) => {
              let minBeds: number = null;
              let maxBeds: number = null;
              if (value) {
                const parsedValue = Number(value.replace(/\+/g, ''));
                minBeds = parsedValue;
                if (!value.endsWith('+')) {
                  maxBeds = parsedValue;
                }
              }

              setForm((f) => ({
                ...f,
                minBeds,
                maxBeds,
              }));
            }}
            label={t('Beds')}
            position="bottom-left"
          />
        </div>
        <div className={clsx('w-full')}>
          <Dropdown
            dense
            data={tBaths}
            selectedValue={selectedBaths}
            onChangeValue={(value: string) => {
              let minBaths: number = null;
              let maxBaths: number = null;
              if (value) {
                const parsedValue = Number(value.replace(/\+/g, ''));
                minBaths = parsedValue;
                if (!value.endsWith('+')) {
                  maxBaths = parsedValue;
                }
              }
              setForm((f) => ({
                ...f,
                minBaths,
                maxBaths,
              }));
            }}
            label={t('Baths')}
            position="top-left"
          />
        </div>
        {form.type === 'lease' && (
          <div className={clsx('w-full')}>
            <Dropdown
              dense
              data={searchLookup.furnishings}
              selectedValue={form.furnishing}
              onChangeValue={(v) => handleFormValueChange(v, 'furnishing')}
              label={t('Furnishing')}
              position="bottom-left"
            />
          </div>
        )}
        <div className={clsx('w-full')}>
          <Input
            margin="dense"
            placeholder={t('Type Keywords...')}
            label={t('Keywords')}
            name="keywords"
            value={form.keywords || ''}
            variant="default"
            onChange={(e) => handleFormValueChange(e.target.value, 'keywords')}
          />
        </div>
        <div className={clsx('w-full')}>
          <Dropdown
            dense
            data={tListedInDaysOptions}
            onChangeValue={(value) =>
              handleFormValueChange(value, 'listedInDays')
            }
            selectedValue={form.listedInDays ? String(form.listedInDays) : ''}
            label={t('Listed')}
            position="top-left"
          />
        </div>
        <div className={clsx('w-full')}>
          <Dropdown
            dense
            data={tSortBy}
            onChangeValue={(value) => handleFormValueChange(value, 'sortBy')}
            selectedValue={form.sortBy}
            label={t('Sort by')}
            position="top-left"
          />
        </div>
      </div>
      <div className="w-full">
        <button
          className={clsx(
            'py-2 px-4 bg-primary-400 text-white hover:bg-primary-500 transform duration-200 rounded w-full'
          )}
          onClick={handleSubmit}
        >
          {t('Search')}
        </button>
      </div>
    </div>
  );
}
