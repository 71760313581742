import CloseIcon from '@/icons/close';
import clsx from 'clsx';
import React from 'react';

export interface ChipProps {
  label: string | React.ReactElement;
  readonly?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
  highlight?: boolean;
}

export const Chip: React.FC<ChipProps> = React.memo((props) => {
  const handleRemove = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (props.onRemove) {
      props.onRemove();
    }
  };

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div
      className={clsx(
        'flex items-center rounded-lg ml-2 mt-2',
        props.onClick && 'cursor-pointer',
        props.highlight
          ? 'border-primary-400 bg-primary-200'
          : 'border-primary-200 bg-primary-50'
      )}
      style={{ height: 32, borderWidth: 2 }}
      onClick={handleClick}
    >
      <span className="px-2 text-sm">{props.label}</span>
      {!props.readonly && (
        <button
          className="flex items-center justify-center bg-red-800 rounded-lg text-white"
          style={{ height: 28, width: 28 }}
          onClick={handleRemove}
        >
          <CloseIcon width={12} height={12} fill="currentColor" />
        </button>
      )}
    </div>
  );
});

Chip.displayName = 'Chip';
