import { ClickAway } from '@/components/ui/click-away';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Chip } from './chip';
import 'rc-slider/assets/index.css';
import { formatCash, formatCurrency } from '@/utility';
import Range, { RangeValue } from './range';
import { useTranslation } from '@/hooks/use-translation';

interface ChipPriceProps {
  minPrice: number;
  maxPrice: number;
  maxPriceLimit: number;
  step: number;
  readonly?: boolean;
  onRemove?: () => void;
  onChange?: (values: [number, number]) => void;
}

export function ChipPrice(props: ChipPriceProps) {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const label: string | React.ReactElement = useMemo(() => {
    if (props.minPrice && props.maxPrice) {
      return (
        <>
          {`$${formatCash(props.minPrice)}`} &nbsp;-&nbsp;{' '}
          {`$${formatCash(props.maxPrice)}`}
        </>
      );
    } else if (props.minPrice) {
      return <>{`$${formatCash(props.minPrice)}`}+</>;
    } else if (props.maxPrice) {
      return <>Upto {`$${formatCash(props.maxPrice)}`}</>;
    }

    return t('Price');
  }, [props.minPrice, props.maxPrice, t]);
  const debounceTimer = useRef(null);

  const propsValues: [number, number] = useMemo(() => {
    let minPrice = props.minPrice;
    let maxPrice = props.maxPrice;

    if (!minPrice) {
      minPrice = 0;
    }

    if (!maxPrice) {
      maxPrice = props.maxPriceLimit;
    } else if (maxPrice > props.maxPriceLimit) {
      maxPrice = props.maxPriceLimit;
    }

    return [minPrice, maxPrice];
  }, [props.minPrice, props.maxPrice, props.maxPriceLimit]);

  const [values, setValues] = useState<[number, number]>(propsValues);

  useEffect(() => {
    setValues(propsValues);
  }, [propsValues]);

  const handleClick = () => {
    setShow(true);
  };

  const handleClickAway = () => {
    if (show) {
      setShow(false);
    }
  };

  const handleChange = (values: RangeValue) => {
    setValues(values);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      const minPrice = values[0] ? values[0] : null;
      const maxPrice = values[1] !== props.maxPriceLimit ? values[1] : null;
      if (props.minPrice !== minPrice || props.maxPrice !== maxPrice) {
        if (props.onChange) {
          props.onChange([minPrice, maxPrice]);
        }
      }
    }, 500);
  };

  return (
    <ClickAway onClickAway={handleClickAway} className="relative" value={show}>
      <Chip
        label={label}
        readonly={props.readonly}
        onRemove={props.onRemove}
        onClick={handleClick}
        highlight={Boolean(props.minPrice) || Boolean(props.maxPrice)}
      />
      <div
        className={clsx(
          'origin-top-right absolute left-0 md:left-0  mt-1 bg-white shadow-lg min-w-52 md:min-w-96 max-w-7xl border rounded z-50 overflow-auto px-3 py-3',
          { hidden: !show },
          { block: show }
        )}
      >
        <div className="flex flex-col">
          <div className="flex text-sm text-gray-500">
            <span>{formatCurrency(values[0])}</span>&nbsp;&#8211;&nbsp;
            <span>{formatCurrency(values[1])}</span>
          </div>

          <div className="mt-2 p-2">
            <Range
              min={0}
              max={props.maxPriceLimit}
              value={values}
              step={props.step}
              onChange={(value) => handleChange(value)}
            />
          </div>
        </div>
      </div>
    </ClickAway>
  );
}
