import React, { useMemo } from 'react';

import ArrowRight from '@/icons/arrow-right';
import { generatePaginationData2 } from '@/utility';
import { Link } from '@/components/ui/link';
import clsx from 'clsx';

export interface PaginationProps {
  page: number;
  numPages: number;
  count: number;
  onClick?: (page: number) => void;
  loading?: boolean;
  getLink: (page: number) => string;
}

export function Pagination({
  page,
  numPages,
  count,
  onClick,
  loading,
  getLink,
}: PaginationProps) {
  const {
    nextPageNumber,
    nextPageNumbers,
    previousPageNumber,
    previousPageNumbers,
  } = useMemo(() => generatePaginationData2(page, numPages), [page, numPages]);

  const previousPageQuery = useMemo(
    () => getLink(previousPageNumber),
    [previousPageNumber, getLink]
  );
  const nextPageQuery = useMemo(
    () => getLink(nextPageNumber),
    [nextPageNumber, getLink]
  );
  const previousPages = useMemo(
    () => previousPageNumbers.map(getLink),
    [previousPageNumbers, getLink]
  );
  const nextPages = useMemo(
    () => nextPageNumbers.map(getLink),
    [nextPageNumbers, getLink]
  );

  const handlePageClick = (page: number) => (e: React.MouseEvent) => {
    if (onClick) {
      e.preventDefault();
      e.stopPropagation();
      onClick(page);
    }
  };

  return (
    <section
      id="pagination"
      className="flex flex-1 md:justify-end items-center flex-wrap md:-mr-3"
    >
      {page > 1 && (
        <Link href={previousPageQuery} prefetch={false}>
          <button
            disabled={loading}
            onClick={handlePageClick(page - 1)}
            className={clsx(
              'flex items-center space-x-1 text-primary-400 hover:text-white hover:bg-primary-500 transform duration-200 border-primary-400 px-4 py-1 border rounded-sm mr-3 mb-3',
              {
                'cursor-wait': loading,
              }
            )}
          >
            <ArrowRight className="h-3 transform rotate-180 fill-current" />
            <span className="block">Previous</span>
          </button>
        </Link>
      )}

      {previousPages.map((link, i) => (
        <Link href={link} key={link} prefetch={false}>
          <button
            disabled={loading}
            onClick={handlePageClick(previousPageNumbers[i])}
            className={clsx(
              'border bg-transparent text-gray-600 px-4 py-1 text-base rounded-sm mr-3 mb-3 hover:bg-primary-400 hover:text-white transform duration-200',
              {
                'cursor-wait': loading,
              }
            )}
          >
            {previousPageNumbers[i]}
          </button>
        </Link>
      ))}

      {count > 0 && (
        <button className="bg-primary-400 text-white px-4 py-1 text-base rounded-sm mr-3 mb-3">
          {page}
        </button>
      )}

      {nextPages.map((page, i) => (
        <Link href={page} key={page} prefetch={false}>
          <button
            disabled={loading}
            onClick={handlePageClick(nextPageNumbers[i])}
            className={clsx(
              'border bg-transparent text-gray-600 px-4 py-1 text-base rounded-sm mr-3 mb-3 hover:bg-primary-400 hover:text-white transform duration-200',
              {
                'cursor-wait': loading,
              }
            )}
          >
            {nextPageNumbers[i]}
          </button>
        </Link>
      ))}

      {page < numPages && (
        <Link href={nextPageQuery} prefetch={false}>
          <button
            disabled={loading}
            onClick={handlePageClick(page + 1)}
            className={clsx(
              'flex items-center space-x-1 text-primary-400 hover:text-white hover:bg-primary-500 transform duration-200 border-primary-400 px-4 py-1 border rounded-sm mr-3 mb-3',
              {
                'cursor-wait': loading,
              }
            )}
          >
            <span className="block">Next</span>
            <ArrowRight className="h-3 transform fill-current" />
          </button>
        </Link>
      )}
    </section>
  );
}
