import { SearchBar } from '@/components/listing';
import { useGoogleOneTapLogin } from '@/components/one-tap-sign-in';
import { RecentProperties } from '@/components/recent-properties';
import { Button } from '@/components/ui/button';
import { Pagination } from '@/components/ui/pagination';
import { useProperty } from '@/context/property';
import { useAuth } from '@/context/auth';
import AuthService from '@/services/auth.service';
import { filterToQuery, transformObjectToQuery } from '@/utility';
import { useEffect, useMemo, useRef } from 'react';
import { PropertyListView } from '../property-list-view';
import { PropertyMapView } from '../property-map-view';
import PropertyTableView from '../property-table-view';
import { PropertyNavbar } from '../../common/navbar';
import PropertyViewSwitch from '../property-view-switch';
import clsx from 'clsx';
import useBoundingClientRect from '@/hooks/use-bounding-client-rect';
import { useScrollPosition } from '@/hooks/use-scroll-position';

interface Pagination {
  count: number;
  numPages: number;
  page: number;
}

// export interface PropertyListLayoutProps {
//   properties: Property[];
//   pagination: Pagination;
// }

export const PropertyListLayout: React.FC = () => {
  const { loading, isAuthenticated, hasTableViewAccess } = useAuth();
  useGoogleOneTapLogin();
  const { view, filter, map, pagination, filterUpdate } = useProperty();
  const isTypeSold = useMemo(
    () => filter.type === 'sold' || filter.type === 'leased',
    [filter.type]
  );

  const openAuthModal = () => {
    const authService = new AuthService();
    authService.openAuthModel();
  };

  useEffect(() => {
    if (loading || isAuthenticated) return;

    if (filter.type === 'sold' || filter.type === 'leased') {
      openAuthModal();
    }
  }, [loading, isAuthenticated, filter.type]);

  const navbarRef = useRef(null);
  const bounds = useBoundingClientRect(navbarRef, 1000);
  const scroll = useScrollPosition(500);

  if (isTypeSold) {
    if (loading) return null;

    if (!isAuthenticated) {
      return (
        <div>
          <div id="property-main" className="px-4 md:px-8">
            <section>
              <div className="flex flex-wrap flex-col flex-1 mt-6 mb-2 md:my-6">
                <SearchBar />
              </div>
              <div className="flex flex-col items-center ">
                <h4 className="text-2xl text-gray-500">
                  Please login to view sold or leased properties!
                </h4>
                <Button
                  className="rounded-md mt-4 mb-16"
                  onClick={openAuthModal}
                >
                  Login
                </Button>
              </div>
            </section>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="mb-20">
      <div>
        <div
          ref={navbarRef}
          className={clsx(
            'fixed w-full bg-white z-50',
            scroll.top > 0 && 'shadow-md'
          )}
        >
          <PropertyNavbar />
        </div>
        <div style={{ height: bounds?.height }}></div>
      </div>
      <hr />
      <div id="property-main" className="px-2 md:px-8">
        <section>
          <div className="flex flex-wrap flex-col flex-1">
            <SearchBar />
            <PropertyViewSwitch />
          </div>
          {view === 'list' ? (
            <div className="mt-16">
              <PropertyListView />
            </div>
          ) : (
            <PropertyMapView />
          )}
        </section>
        {view === 'list' && (
          <div className="mt-4">
            <Pagination
              count={pagination.count}
              numPages={pagination.numPages}
              page={filter.page}
              getLink={(page: number) =>
                `/properties?${transformObjectToQuery(
                  filterToQuery(
                    {
                      ...filter,
                      page,
                    },
                    view,
                    map.position
                  )
                )}`
              }
              onClick={(page: number) => {
                filterUpdate({
                  page,
                });
                document
                  .querySelector('#property-main')
                  ?.scrollIntoView({ behavior: 'smooth' });
              }}
            />
          </div>
        )}
      </div>
      {hasTableViewAccess && view === 'map' && (
        <section className="w-full">
          <PropertyTableView />
        </section>
      )}
      <section className="px-4 md:px-8 pb-8">
        <RecentProperties />
      </section>
    </div>
  );
};
